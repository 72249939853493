/* eslint-disable no-useless-constructor */
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "./PrivateRoute";
import { AgentRoute } from "./AgentRoute";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Role } from "./Roles";
import ExportCSV from "./components/Dashboard/ExportCSV";
import { UserData } from "./components/Test/UserData";
import Logout from "./components/Login/Logout";

const Loginss = React.lazy(() => import("./components/Login/Login"));
const CommonLogin = React.lazy(() => import("./components/Login/CommonLogin"));

const Loan = React.lazy(() => import("./components/Loan/Loan"));
const ClientAdmin_Loan = React.lazy(() =>
  import("./components/Loan/CommonAllLoans")
);
const UploadLoan = React.lazy(() => import("./components/Loan/UploadLoan"));
const AssignLoanUpload = React.lazy(() =>
  import("./components/Loan/autoLoanAllocation")
);
const Dashboard = React.lazy(() => import("./components/Dashboard/Dashboard"));
const TestDashboard = React.lazy(() =>
  import("./components/Dashboard/TestDashboard")
);
const Notfound = React.lazy(() => import("./Notfound"));
const SortingRules = React.lazy(() =>
  import("./components/AllocationRule/SortingRules")
);
const AllocationRule = React.lazy(() =>
  import("./components/AllocationRule/AllocationRule")
);
const TmpLoan = React.lazy(() => import("./components/AllocationRule/TmpLoan"));
const ImportMapping = React.lazy(() =>
  import("./components/Loan/ImportMapping")
);
const UpdateImportMapping = React.lazy(() =>
  import("./components/Loan/UpdateImportMapping")
);
const ManualEntry = React.lazy(() => import("./components/Loan/ManualEntry"));

const Collector = React.lazy(() => import("./components/Collector/Collector"));
const Manager = React.lazy(() => import("./components/Manager/Manager"));
const UserDatas = React.lazy(() => import("./components/Test/UserData"));
const UserList = React.lazy(() => import("./components/Test/UserList"));
const Contact = React.lazy(() => import("./components/Contact/Contact"));
const CreateManager = React.lazy(() =>
  import("./components/Manager/CreateManager")
);
const CreateCollector = React.lazy(() =>
  import("./components/Collector/CreateCollector")
);
const OldCreateFieldAgent = React.lazy(() =>
  import("./components/ManagerCollector/OldCreateFieldAgent")
);
const Client = React.lazy(() => import("./components/Client/Client"));
const CreateClient = React.lazy(() =>
  import("./components/ClientProfiling/CreateClient/CreateClient")
);
const OldCreateClient = React.lazy(() =>
  import("./components/Client/CreateClient")
);
const PostAllocation = React.lazy(() =>
  import("./components/ClientProfiling/PostAllocation/PostAllocation")
);
const PostPayment = React.lazy(() =>
  import("./components/ClientProfiling/PostPayment/PostPayment")
);
const Settlement = React.lazy(() =>
  import("./components/ClientProfiling/Settlement/Settlement")
);

const ViewClient = React.lazy(() =>
  import("./components/ClientProfiling/ViewClient/ViewClient")
);
const TargetSetting = React.lazy(() =>
  import("./components/ClientProfiling/TargetSetting/TargetSetting")
);
const IncentiveStructure = React.lazy(() =>
  import("./components/ClientProfiling/IncentiveStructure/IncentiveStructure")
);
const CreatePortfolio = React.lazy(() =>
  import("./components/ClientAdmin/CreatePortfolio/CreatePortfolio")
);
const ActiveLoans = React.lazy(() =>
  import("./components/AssignedLoan/ActiveLoans")
);
const Notifications = React.lazy(() =>
  import("./components/Notifications/NewNotification")
);
const settalementLoans = React.lazy(() =>
  import("./components/AssignedLoan/settalementLoans")
);
const ClientSettalementLoans = React.lazy(() =>
  import("./components/AssignedLoan/clientSettalementLoans")
);

const CallManagement = React.lazy(() =>
  import("./components/AssignedLoan/CallManagement")
);
const CallManagementModify = React.lazy(() =>
  import("./components/AssignedLoan/CallManagementModify")
);
const PartnerList = React.lazy(() =>
  import("./components/Partner/PartnerList")
);
const CreatePartner = React.lazy(() =>
  import("./components/Partner/CreatePartner")
);
// const CreateUser = React.lazy(() => import('./components/User/CreateUser'))
const PartnerLoan = React.lazy(() =>
  import("./components/AssignedLoan/PartnerLoan")
);
const CollectorDashboard = React.lazy(() =>
  import("./components/Dashboard/CollectorDashboad")
);
const CollectorLandingPage = React.lazy(() =>
  import("./components/Dashboard/CollectorLandingPage")
);
const PartnerDashboard = React.lazy(() =>
  import("./components/Dashboard/PartnerDashboard")
);
const ManagerDashboard = React.lazy(() =>
  import("./components/Dashboard/ManagerDashboard")
);
const ManagerLandingPage = React.lazy(() =>
  import("./components/Dashboard/ManagerLandingPage")
);
const DataVisualization = React.lazy(() =>
  import("./components/DataVisualization/DataVisualization")
);
const CreateDataVisualization = React.lazy(() =>
  import("./components/DataVisualization/CreateDataVisualization")
);
const CustomerLogin = React.lazy(() =>
  import("./components/Login/CustomerLogin")
);
const CustomerDashboard = React.lazy(() =>
  import("./components/Dashboard/CustomerDashboard")
);
const CreateFile = React.lazy(() => import("./components/Test/CreateFile"));
const PredictiveCall = React.lazy(() =>
  import("./components/AssignedLoan/PredictiveCall")
);
const DailyCallReports = React.lazy(() =>
  import("./components/DailyCallReports/DailyCallReports")
);
const UploadSheets = React.lazy(() =>
  import("./components/DialerReports/UploadSheets.jsx")
);
const AgentWise = React.lazy(() =>
  import("./components/DialerReports/AgentWise.jsx")
);
const PredictiveOutbound = React.lazy(() =>
  import("./components/DialerReports/PredictiveOutbound.jsx")
);
const AgentPerformance = React.lazy(() =>
  import("./components/DialerReports/AgentPerformance.jsx")
);
const Processwise = React.lazy(() =>
  import("./components/DialerReports/Processwise.jsx")
);
const CallRecording = React.lazy(() =>
  import("./components/DialerReports/CallRecording.jsx")
);
const BestDispositionReport = React.lazy(() =>
  import("./components/BestDisposition/BestDispositionReport.jsx")
);

const DialerReports = React.lazy(() =>
  import("./components/DialerReports/DialerReports.jsx")
);

const AllocationReports = React.lazy(() =>
  import("./components/DailyCallReports/AllocationReports")
);
const WorkableReports = React.lazy(() =>
  import("./components/DailyCallReports/WorkableReports")
);
const AdditionalReports = React.lazy(() =>
  import("./components/DailyCallReports/AdditionalReports")
);
const PendingClientReports = React.lazy(() =>
  import("./components/PendingClientReports/PendingClientReports")
);
const DownloadReports = React.lazy(() =>
  import("./components/DownloadReports/DownloadReports")
);
const DailyFieldAgentReports = React.lazy(() =>
  import("./components/DailyFieldAgentReports/DailyFieldAgentReports")
);
const ManagerCollector = React.lazy(() =>
  import("./components/ManagerCollector/ManagerCollector")
);
const TargetSettings = React.lazy(() =>
  import("./components/ManagerCollector/TargetSettings")
);
const Datainsight = React.lazy(() =>
  import("./components/DataInsight/DataInsight")
);
const DataInsightFile = React.lazy(() =>
  import("./components/DataInsight/DataInsightFile")
);
const Target = React.lazy(() => import("./components/Target"));
const FieldTarget = React.lazy(() => import("./components/Target/FieldTarget"));
const ViewLoan = React.lazy(() => import("./components/Loan/ViewLoan"));
const approveLoan = React.lazy(() => import("./components/Loan/approveLoan"));
const approveLoanByClient = React.lazy(() =>
  import("./components/Loan/approveLoanbyClient")
);
const letterUploadByClient = React.lazy(() =>
  import("./components/LetterUpload/letterUploadByClient")
);
const clientlist = React.lazy(() =>
  import("./components/CustomerData/Clientlist")
);
const clientedit = React.lazy(() =>
  import("./components/CustomerData/Clientedit")
);
const clientlogin = React.lazy(() =>
  import("./components/CustomerData/Clientlogin")
);
const EditDisposition = React.lazy(() =>
  import("./components/Loan/EditDisposition")
);
const ManagerLoan = React.lazy(() =>
  import("./components/Manager/ManagerLoan")
);
const RecoveryPredictor = React.lazy(() =>
  import("./components/RecoveryPredictor/RecoveryPredictor")
);
const RecoveryInsight = React.lazy(() =>
  import("./components/RecoveryInsight/RecoveryInsight")
);
const SheetList = React.lazy(() => import("./components/Loan/SheetList"));
const ExtendLoans = React.lazy(() => import("./components/Loan/ExtendLoans"));
const FieldAgentLoan = React.lazy(() =>
  import("./components/Loan/FieldAgentLoan")
);
const Backup = React.lazy(() => import("./components/Backup/Backup"));
const LastInteractionReports = React.lazy(() =>
  import("./components/DailyCallReports/LastInteractionReports")
);
const RetainReport = React.lazy(() =>
  import("./components/DailyCallReports/RetainReport")
);
const Reports = React.lazy(() =>
  import("./components/DailyCallReports/Reports")
);
const BankPaidBulkUpload = React.lazy(() =>
  import("./components/Loan/BankPaidBulkUpload")
);
const Attendance = React.lazy(() =>
  import("./components/ManagerCollector/Attendance")
);
const EncoreReports = React.lazy(() =>
  import("./components/DailyCallReports/EncoreReports")
);
const Profile = React.lazy(() => import("./components/profile/Profile"));
const CreateFieldAgent = React.lazy(() =>
  import("./components/ManagerCollector/CreateFieldAgent")
);
const ManagerProfile = React.lazy(() =>
  import("./components/profile/ManagerProfile")
);
const CollectorProfile = React.lazy(() =>
  import("./components/profile/CollectorProfile")
);
const RevenueList = React.lazy(() =>
  import("./components/Revenue/CollectorRevenueList")
);
const EditRevenue = React.lazy(() =>
  import("./components/Revenue/CreateCollectorRevenue")
);
const SuccessFees = React.lazy(() =>
  import("./components/SuccessFees/SuccessFees")
);
const SuccessFeesEdit = React.lazy(() =>
  import("./components/SuccessFees/SuccessFeesEdit")
);
const ClientRevenue = React.lazy(() =>
  import("./components/Revenue/ClientRevenue")
);
const ModifyLoanByClient = React.lazy(() =>
  import("./components/Loan/ModifyLoanByClient")
);
const ClientCallManagement = React.lazy(() =>
  import("./components/AssignedLoan/ClientCallManagement")
);
const agentProfiling = React.lazy(() =>
  import("./components/AgentProfiling/AgentProfiling")
);
const RedirectBi = React.lazy(() =>
  import("./components/RedirectBi/RedirectBi")
);
const Reconciliation = React.lazy(() =>
  import("./components/Reconciliation/Reconciliation")
);
const LoginReport = React.lazy(() =>
  import("./components/DownloadReports/LoginReport")
);

const AgentMonitor = React.lazy(() =>
  import("./components/AgentMonitor/AgentMonitor")
);

const ProcessAllocation = React.lazy(() =>
  import("./components/ProcessAllocation/ProcessAllocation")
);
const PredictiveListUpload = React.lazy(() =>
  import("./components/PredictiveListUpload/PredictiveListUpload.jsx")
);

const CommonAllLoans = React.lazy(() =>
  import("./components/Loan/CommonAllLoans")
);

const CommonManagerLoan = React.lazy(() =>
  import("./components/Loan/ManagerLoan")
);
class Routes extends Component {
  constructor(props) {
    super(props);
  }
  static propTypes = {
    authentication: PropTypes.object.isRequired,
  };

  render() {
    const { collektoUser } = this.props.authentication;

    return (
      <React.Fragment>
        <Switch>
          <Route path="/logout" component={Logout} />
          {collektoUser &&
          (collektoUser.role === "ADMIN" ||
            collektoUser.role === "SUPER_ADMIN") ? (
            <PrivateRoute
              exact
              path="/"
              component={TestDashboard}
              label="Home"
            />
          ) : collektoUser && collektoUser.role === "COLLECTOR" ? (
            <PrivateRoute
              exact
              path="/"
              roles={[Role.COLLECTOR]}
              component={CollectorDashboard}
              label="Home"
            />
          ) : collektoUser && collektoUser.role === "PARTNER" ? (
            <PrivateRoute
              exact
              path="/"
              roles={[Role.PARTNER]}
              component={PartnerDashboard}
              label="Home"
            />
          ) : collektoUser && collektoUser.role === "CUSTOMER" ? (
            <PrivateRoute
              exact
              path="/"
              roles={[Role.CUSTOMER]}
              component={CustomerDashboard}
              label="Home"
            />
          ) : collektoUser && collektoUser.role === "FIELD_AGENT" ? (
            <PrivateRoute
              exact
              path="/"
              roles={[Role.FIELD_AGENT]}
              component={clientlist}
              label="Home"
            />
          ) : collektoUser && collektoUser.role === "MANAGER" ? (
            <PrivateRoute
              exact
              path="/"
              roles={[Role.MANAGER]}
              component={ManagerDashboard}
              label=""
            />
          ) : collektoUser && collektoUser.role === "MANAGER" ? (
            <PrivateRoute
              exact
              path="/"
              roles={[Role.ADMIN, Role.SUPER_ADMIN]}
              component={TestDashboard}
              label="Home"
            />
          ) : (
            <PrivateRoute
              exact
              path="/"
              roles={[Role.CLIENT_ADMIN]}
              component={ClientAdmin_Loan}
              label="ClientAdmin"
            />
          )}

          {/* <PrivateRoute path="/user-list" component={UserList} />
<PrivateRoute roles={[Role.ADMIN]} path="/user/create" component={CreateUser} /> */}
          <PrivateRoute
            path="/agentProfiling"
            roles={[
              Role.ADMIN,
              Role.MANAGER,
              Role.COLLECTOR,
              Role.CUSTOMER,
              Role.CLIENT_ADMIN,
            ]}
            component={agentProfiling}
          />
          <PrivateRoute
            path="/loans/auto-allocation"
            roles={[Role.ADMIN, Role.CLIENT_ADMIN, Role.MANAGER]}
            component={AssignLoanUpload}
          />
          <PrivateRoute
            path="/redirectBi"
            roles={[
              Role.ADMIN,
              Role.CLIENT_ADMIN,
              Role.MANAGER,
              Role.COLLECTOR,
            ]}
            component={RedirectBi}
          />
          {collektoUser && collektoUser.role === "MANAGER" ? (
            <PrivateRoute
              exact
              path="/home"
              roles={[Role.MANAGER]}
              component={ManagerLandingPage}
            />
          ) : (
            <PrivateRoute
              exact
              path="/home"
              roles={[Role.COLLECTOR]}
              component={CollectorLandingPage}
            />
          )}
          <PrivateRoute
            roles={[Role.ADMIN]}
            path="/test-dashboard"
            component={Dashboard}
          />
          <PrivateRoute
            roles={[Role.ADMIN, Role.CLIENT_ADMIN]}
            path="/upload-loan"
            component={UploadLoan}
          />
          <PrivateRoute
            roles={[Role.ADMIN]}
            path="/loan/manual-entry"
            component={ManualEntry}
          />
          <PrivateRoute
            roles={[Role.ADMIN, Role.CLIENT_ADMIN]}
            path="/loan/import-mapping"
            component={ImportMapping}
          />
          <PrivateRoute
            roles={[Role.ADMIN, Role.CLIENT_ADMIN]}
            path="/loan/update-import-mapping"
            component={UpdateImportMapping}
          />
          <PrivateRoute
            roles={[Role.ADMIN]}
            path="/bank-import Notifications from './components/Notifications/Notifications';
paid-bulk-upload"
            component={BankPaidBulkUpload}
          />
          <PrivateRoute
            roles={[Role.ADMIN, Role.CLIENT_ADMIN]}
            path="/loans"
            component={CommonAllLoans}
          />
          <PrivateRoute
            roles={[Role.ADMIN]}
            path="/contact"
            component={Contact}
          />
          <PrivateRoute
            roles={[Role.ADMIN]}
            path="/backup"
            component={Backup}
          />
          <PrivateRoute
            roles={[Role.ADMIN, Role.MANAGER]}
            path="/view-loan"
            component={ViewLoan}
          />
          <PrivateRoute
            roles={[Role.ADMIN, Role.MANAGER]}
            path="/approveLoan"
            component={approveLoan}
          />
          <PrivateRoute
            roles={[Role.ADMIN, Role.CUSTOMER]}
            path="/approveLoanByClient"
            component={approveLoanByClient}
          />
          <PrivateRoute
            roles={[Role.CUSTOMER]}
            path="/letterUploadByClient"
            component={letterUploadByClient}
          />
          <PrivateRoute
            roles={[Role.ADMIN]}
            path="/sheet-list"
            component={SheetList}
          />
          <PrivateRoute
            roles={[Role.ADMIN]}
            path="/extend-loans"
            component={ExtendLoans}
          />
          <PrivateRoute
            roles={[Role.ADMIN]}
            path="/encore-reports"
            component={EncoreReports}
          />
          <PrivateRoute
            roles={[Role.ADMIN]}
            path="/userdata"
            component={UserDatas}
          />
          <PrivateRoute
            roles={[Role.ADMIN]}
            path="/userlist"
            component={UserList}
          />
          <PrivateRoute
            roles={[Role.ADMIN]}
            path="/myprofile"
            component={Profile}
          />
          <PrivateRoute
            roles={[
              Role.ADMIN,
              Role.MANAGER,
              Role.COLLECTOR,
              Role.CLIENT_ADMIN,
              Role.CUSTOMER,
            ]}
            path="/daily-reports"
            component={DailyCallReports}
          />
          <PrivateRoute
            roles={[
              Role.ADMIN,
              Role.MANAGER,
              Role.COLLECTOR,
              Role.CLIENT_ADMIN,
              Role.CUSTOMER,
            ]}
            path="/allocation-reports"
            component={AllocationReports}
          />

          <PrivateRoute
            roles={[
              Role.ADMIN,
              Role.MANAGER,
              Role.COLLECTOR,
              Role.CLIENT_ADMIN,
              Role.CUSTOMER,
            ]}
            path="/additional-reports"
            component={AdditionalReports}
          />
          <PrivateRoute
            roles={[
              Role.ADMIN,
              Role.MANAGER,
              Role.COLLECTOR,
              Role.CLIENT_ADMIN,
              Role.CUSTOMER,
            ]}
            path="/workable-reports"
            component={WorkableReports}
          />
          <PrivateRoute
            roles={[Role.COLLECTOR, Role.MANAGER, Role.CUSTOMER]}
            path="/retain-report"
            component={RetainReport}
          />
          <PrivateRoute
            roles={[Role.MANAGER]}
            path="/login-report"
            component={LoginReport}
          />
          <PrivateRoute
            roles={[Role.MANAGER]}
            path="/pending-client-reports"
            component={PendingClientReports}
          />
          <PrivateRoute
            roles={[
              Role.ADMIN,
              Role.MANAGER,
              Role.COLLECTOR,
              Role.CUSTOMER,
              Role.CLIENT_ADMIN,
            ]}
            path="/download-reports"
            component={DownloadReports}
          />
          <PrivateRoute
            roles={[Role.ADMIN, Role.MANAGER, Role.COLLECTOR]}
            path="/reports"
            component={Reports}
          />
          <PrivateRoute
            roles={[Role.ADMIN, Role.CLIENT_ADMIN, Role.MANAGER, Role.COLLECTOR]}
            path="/best-disposition"
            component={BestDispositionReport}
          />
          <PrivateRoute
            roles={[Role.ADMIN]}
            path="/upload-sheets"
            component={UploadSheets}
          />
          <PrivateRoute
            roles={[Role.ADMIN, Role.CLIENT_ADMIN, Role.MANAGER]}
            path="/agent-login-report"
            component={AgentWise}
          />
          <PrivateRoute
            roles={[Role.ADMIN, Role.CLIENT_ADMIN]}
            path="/predictive-report"
            component={PredictiveOutbound}
          />
          <PrivateRoute
            roles={[Role.ADMIN, Role.CLIENT_ADMIN, Role.MANAGER]}
            path="/agent-performance-report"
            component={AgentPerformance}
          />
          <PrivateRoute
            roles={[Role.ADMIN, Role.CLIENT_ADMIN, Role.MANAGER]}
            path="/process-wise-report"
            component={Processwise}
          />
          <PrivateRoute
            roles={[Role.ADMIN, Role.CLIENT_ADMIN, Role.MANAGER]}
            path="/agent-call-recording"
            component={CallRecording}
          />
          <PrivateRoute
            roles={[Role.ADMIN, Role.MANAGER, Role.CLIENT_ADMIN, Role.CUSTOMER]}
            path="/daily-field-reports"
            component={DailyFieldAgentReports}
          />

          <PrivateRoute
            roles={[Role.MANAGER, Role.CUSTOMER, Role.CLIENT_ADMIN]}
            path="/target-settings"
            component={TargetSettings}
          />

          <PrivateRoute
            roles={[Role.ADMIN]}
            exact
            path="/data-visualizations"
            component={DataVisualization}
            label="Home"
          />
          <PrivateRoute
            roles={[Role.ADMIN]}
            path="/data-visualization/new"
            component={CreateDataVisualization}
          />
          <PrivateRoute
            roles={[Role.ADMIN]}
            path="/data-visualization/edit"
            component={CreateDataVisualization}
          />

          <PrivateRoute
            roles={[Role.ADMIN, Role.PARTNER]}
            path="/managers"
            component={Manager}
          />
          <PrivateRoute
            roles={[Role.ADMIN, Role.PARTNER]}
            path="/managers-collectors"
            component={ManagerCollector}
          />
          <PrivateRoute
            roles={[Role.ADMIN, Role.MANAGER]}
            path="/process-allocation"
            component={ProcessAllocation}
          />
          <PrivateRoute
            roles={[Role.ADMIN, Role.CLIENT_ADMIN]}
            path="/predictive-list-upload"
            component={PredictiveListUpload}
          />
          <PrivateRoute
            roles={[Role.ADMIN, Role.PARTNER]}
            path="/manager/create"
            component={CreateManager}
          />
          <PrivateRoute
            roles={[Role.ADMIN, Role.PARTNER]}
            path="/manager/edit"
            component={CreateManager}
          />
          <PrivateRoute
            roles={[Role.ADMIN]}
            path="/field-agent/create"
            component={CreateFieldAgent}
          />
          <PrivateRoute
            roles={[Role.ADMIN]}
            path="/field-agent/edit"
            component={CreateFieldAgent}
          />

          {/* <PrivateRoute roles={[Role.ADMIN]} path="/collector/field/create" component={OldCreateFieldAgent} /> */}
          {/* <PrivateRoute roles={[Role.ADMIN]} path="/collector/field/edit" component={OldCreateFieldAgent} /> */}

          <PrivateRoute
            roles={[Role.MANAGER]}
            path="/all-loans"
            component={
              CommonManagerLoan
              // ManagerLoan
            }
          />
          <PrivateRoute
            path="/last-interaction-report"
            component={LastInteractionReports}
          />
          <PrivateRoute
            roles={[Role.ADMIN]}
            path="/sortingrules"
            component={SortingRules}
          />
          <PrivateRoute
            roles={[Role.ADMIN]}
            path="/allocation-rule"
            component={AllocationRule}
          />
          <PrivateRoute
            roles={[Role.ADMIN]}
            path="/allocation-rule-loans"
            component={TmpLoan}
          />

          <PrivateRoute
            roles={[Role.ADMIN]}
            path="/collectors"
            component={Collector}
          />
          <PrivateRoute
            roles={[Role.ADMIN]}
            path="/collector/create"
            component={CreateCollector}
          />
          <PrivateRoute
            roles={[Role.ADMIN]}
            path="/collector/edit"
            component={CreateCollector}
          />

          <PrivateRoute
            roles={[Role.ADMIN, Role.MANAGER]}
            path="/attendance/:id"
            component={Attendance}
          />

          <PrivateRoute
            roles={[Role.ADMIN]}
            path="/partner-list"
            component={PartnerList}
          />
          <PrivateRoute
            roles={[Role.ADMIN]}
            path="/partner/create"
            component={CreatePartner}
          />
          <PrivateRoute
            roles={[Role.ADMIN]}
            path="/partner/edit"
            component={CreatePartner}
          />
          <PrivateRoute
            roles={[Role.ADMIN]}
            path="/success-fees"
            component={SuccessFees}
          />
          <PrivateRoute
            roles={[Role.ADMIN]}
            path="/successfees/edit"
            component={SuccessFeesEdit}
          />

          <PrivateRoute
            roles={[Role.ADMIN]}
            path="/test/createfile"
            component={CreateFile}
          />

          <PrivateRoute path="/Recovery" component={RecoveryPredictor} />
          <PrivateRoute path="/recovery-insight" component={RecoveryInsight} />

          <AgentRoute path="/field-agent/loan/edit" component={clientedit} />
          <Route path="/field-agent/login" component={clientlogin} />

          <PrivateRoute path="/data-insights" component={Datainsight} />
          <PrivateRoute
            path="/data-insights-file"
            component={DataInsightFile}
          />

          <PrivateRoute path="/target/:id" component={Target} />
          <PrivateRoute path="/field-target/:id" component={FieldTarget} />
          {/* <PrivateRoute path="/analytical-dashboard" component={AnalyticalDashboard} /> */}
          <PrivateRoute
            roles={[Role.ADMIN]}
            path="/clients"
            component={Client}
          />
          <PrivateRoute
            roles={[Role.ADMIN]}
            path="/create-client"
            component={CreateClient}
          />
          <PrivateRoute
            roles={[Role.ADMIN]}
            path="/old-create-client"
            component={OldCreateClient}
          />
          <PrivateRoute
            roles={[Role.ADMIN, Role.CLIENT_ADMIN]}
            path="/post-allocation"
            component={PostAllocation}
          />
          <PrivateRoute
            roles={[Role.ADMIN, Role.CLIENT_ADMIN]}
            path="/post-payment"
            component={PostPayment}
          />
          <PrivateRoute
            roles={[Role.ADMIN, Role.CLIENT_ADMIN]}
            path="/settlement"
            component={Settlement}
          />
          <PrivateRoute
            roles={[Role.ADMIN, Role.CLIENT_ADMIN]}
            path="/view-client"
            component={ViewClient}
          />
          <PrivateRoute
            roles={[Role.ADMIN, Role.CLIENT_ADMIN]}
            path="/target-setting"
            component={TargetSetting}
          />
          <PrivateRoute
            roles={[Role.ADMIN, Role.CLIENT_ADMIN]}
            path="/incentive-structure"
            component={IncentiveStructure}
          />
          <PrivateRoute
            roles={[Role.ADMIN]}
            path="/client/edit"
            component={OldCreateClient}
          />
          <PrivateRoute
            roles={[Role.CLIENT_ADMIN]}
            path="/create-portfolio"
            component={CreatePortfolio}
          />

          <PrivateRoute
            roles={[Role.ADMIN]}
            path="/revenues"
            component={RevenueList}
          />
          <PrivateRoute
            roles={[Role.ADMIN]}
            path="/revenue/edit"
            component={EditRevenue}
          />
          <PrivateRoute
            roles={[Role.ADMIN]}
            path="/client-revenue"
            component={ClientRevenue}
          />
          {/* <PrivateRoute roles={[Role.ADMIN]} path="/revenues" component={RevenueList} /> */}
          {/* <PrivateRoute roles={[Role.ADMIN]} path="/profile" component={Profile} /> */}
          {/* <PrivateRoute roles={[Role.ADMIN]} path="/ClientProfile" component={ClientProfile} /> */}
          {/*
            <PrivateRoute roles={[Role.ADMIN]} path="/portfolios" component={Portfolio} />
            <PrivateRoute roles={[Role.ADMIN]} path="/portfolio/create" component={CreatePortfolio} />
            <PrivateRoute roles={[Role.ADMIN]} path="/portfolio/edit" component={CreatePortfolio} /> */}
          {/* <PrivateRoute path="/Supervisor" component={Supervisor} /> */}
          {/* <PrivateRoute path="/employee/edit" component={CreateEmployee} /> */}
          <PrivateRoute path="/export-csv" component={ExportCSV} />

          <PrivateRoute
            path="/Reconciliation"
            roles={[Role.ADMIN, Role.MANAGER, Role.CLIENT_ADMIN]}
            component={Reconciliation}
          />
          <Route path="/login" component={CommonLogin} />
          {/* <Route path="/collector/login" component={CollectorLogin} />
          <Route path="/customer/login" component={CustomerLogin} />
          <Route path="/partner/login" component={PartnerLogin} />
          <Route path="/manager/login" component={ManagerLogin} /> */}
          {/* The above routes were the active routes before the new common login page*/}

          {/* <Route path="/profile/Profile" component={Profile} /> */}
          {/* <PrivateRoute path="/Supervisor" component={Supervisor} /> */}

          <PrivateRoute
            path="/active-loans"
            roles={[Role.COLLECTOR, Role.MANAGER]}
            component={ActiveLoans}
          />
          <PrivateRoute
            path="/notifications"
            roles={[
              Role.COLLECTOR,
              Role.MANAGER,
              Role.CUSTOMER,
              Role.CLIENT_ADMIN,
            ]}
            component={Notifications}
          />
          <PrivateRoute
            path="/agent-monitor"
            roles={[Role.MANAGER]}
            component={AgentMonitor}
          />
          <PrivateRoute
            path="/settalement-loans"
            roles={[Role.CUSTOMER]}
            component={settalementLoans}
          />
          <PrivateRoute
            path="/client-settalement-loans"
            roles={[Role.MANAGER]}
            component={ClientSettalementLoans}
          />
          <PrivateRoute
            path="/profile"
            roles={[Role.MANAGER, Role.CUSTOMER]}
            component={ManagerProfile}
          />
          <PrivateRoute
            path="/partner-loans"
            roles={[Role.PARTNER]}
            component={PartnerLoan}
          />
          <PrivateRoute
            path="/call-management"
            roles={[Role.COLLECTOR, Role.MANAGER]}
            component={CallManagement}
          />
          <PrivateRoute
            path="/call-management-modify"
            roles={[Role.COLLECTOR, Role.MANAGER]}
            component={CallManagementModify}
          />
          <PrivateRoute
            path="/predictive-call"
            roles={[Role.COLLECTOR]}
            component={PredictiveCall}
          />
          <PrivateRoute
            path="/my-profile"
            roles={[Role.COLLECTOR]}
            component={CollectorProfile}
          />
          <PrivateRoute path="/field-agent-loans" component={FieldAgentLoan} />

          <PrivateRoute
            path="/client-call-management"
            roles={[Role.CUSTOMER]}
            component={ClientCallManagement}
          />
          <PrivateRoute
            path="/modify-loan"
            roles={[Role.CUSTOMER]}
            component={ModifyLoanByClient}
          />

          <Route path="*" component={Notfound} />
        </Switch>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authentication: state.authentication,
});
export default connect(mapStateToProps)(Routes);
