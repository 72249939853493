import { targetConstants } from "../constants/ActionTypes"

const initialState = {
    targets: [],
    clients:[],
    cities: [],
    buckets: []
}

export default (state = initialState, { type, payload }) => {
    switch (type) {

    case targetConstants.GET_TARGET_BY_COLLECTOR:
        return { ...state, targets : payload }

    case targetConstants.GET_GROUP_BY_CLIENT:
        return { ...state, clients : payload }

    case targetConstants.GET_CITY:
        return {  ...state, cities : payload }

    case targetConstants.GET_REGION:
        return {  ...state, regions : payload }
    
    case targetConstants.GET_BUCKET:
        return {  ...state, buckets : payload }

    default:
        return state
    }
}