import { dataVisualizationConstants } from "../constants/ActionTypes"

const initialState = {
    dataVisualizations: []
}

export default (state = initialState, { type, payload }) => {
    switch (type) {

    case dataVisualizationConstants.GET_VISUALIZATION_ALL:
        return { ...state, dataVisualizations : payload }

    case dataVisualizationConstants.DELETE_VISUALIZATION:
        return { ...state,
            dataVisualizations : state.dataVisualizations.filter(dv => (dv._id !== payload._id))
        }

    default:
        return state
    }
}
