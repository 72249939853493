import { dataInsightConstants } from "../constants/ActionTypes"

const initialState = {
    attributes:{},
    datainsights : {},
    loading: false,
    uploadDataInsights: [],

}

export default (state = initialState, { type, payload }) => {
    switch (type) {

    case dataInsightConstants.GET_GROUP_ATTRIBUTES:
        return { ...state, attributes:payload }

    // case dataInsightConstants.GET_DATA_INSIGHTS:
    //     return {
    //         ...state,
    //         datainsights:payload
    //     }
    case dataInsightConstants.GET_DATA_SEARCH:
        return {
            ...state,
            datainsights:payload,
            loading: false
        }
    case dataInsightConstants.GET_VERIFY_DATAINSIGHT:
            console.log(`payload `, payload)
            return {
                ...state,
                loanding: false,
                uploadDataInsights: payload
            }
    case dataInsightConstants.VERIFIED_DATAINSIGHT:
                return {
                    ...state,
                    loanding: false,
                    uploadDataInsights: []
                }

    default:
        return state
    }
}
