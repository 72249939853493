import { managerConstants } from "../constants/ActionTypes"

const initialState = {
    managers: [],
    deleteManager:[]
}

export default (state = initialState, { type, payload }) => {
    switch (type) {

    case managerConstants.GET_MANAGER_ALL:
        return { ...state, managers : payload }

    case managerConstants.DELETE_MANAGER:
        return { ...state,
            managers : state.managers.filter(dv => (dv._id !== payload._id))
    }

    case managerConstants.GET_NOTIFICATION_COUNT:
        return {...state, notificationCount: payload?.count}
    default:
        return state
    }
}
