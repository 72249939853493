import { revenueConstants } from "../constants/ActionTypes"

const initialState = {
    revenues: [],
    clientreveues: []
}

const revenueReducer = (state = initialState, { type, payload }) => {

    switch (type) {

    case revenueConstants.GET_REVENUE_ALL:
        return { ...state, revenues : payload }
    case revenueConstants.GET_CLIENT_REVENUE:
        return {...state, clientreveues: payload}
    default:
        return state
    }

}

export default revenueReducer

