import { recoveryPredictorConstants } from "../constants/ActionTypes"

const initialState = {
}

export default (state = initialState, { type, payload }) => {
    switch (type) {

    case recoveryPredictorConstants.GET_RECOVERY_PREDICTOR:
        console.log(`payload `, payload)
        return { ...state, ...payload }
    case recoveryPredictorConstants.UPDATE_RECOVERY_PREDICTOR:
            console.log(`payload `, payload)
            return { ...state, ...payload }
    default:
        return state
    }
}
