import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import SimpleReactValidator from 'simple-react-validator';
import { addUserData, updateUserData} from '../../actions/userDataAction';
import PropTypes from 'prop-types'

class UserData extends Component {
    constructor(props){
        super(props)
        this.validator = new SimpleReactValidator({autoForceUpdate: this});


        this.state = {
            username : '',
            email: '',
            password: '',
            fullName: ''
        }
       

    }

    static propTypes = {
        authentication : PropTypes.object.isRequired
    }

    onChangeHandle = (e) => {
        const { name, value } = e.target;
        this.setState({[name] : value})
    }

    onSubmitHandle = (e) => {
        e.preventDefault()
        if (this.validator.allValid()) {
            console.log('success')
        console.log(`this.state`, this.state)
        if(this.props.match.url === '/userdata'){
            // console.log('add user data',this.props.addUserData)
            this.props.addUserData(this.state)
        }else{
            this.props.updateUserData(this.state)
        

        }

        } else {
            console.log('no changes')
          this.validator.showMessages();
        }
    }


    render() {
        
        // s.replace(/./,m=>m.toUpperCase())
        const {username, email, password, fullName}=this.state
        console.log(this.state)
        return (
            <div className="page-wrapper">
            <div className="container-fluid">
              <div className="row page-titles">
                <div className="col-md-5 align-self-center">
                  <h4 className="text-themecolor">User</h4>
                </div>
                <div className="col-md-7 align-self-center text-right">
                  <div className="d-flex justify-content-end align-items-center">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                      <li className="breadcrumb-item active">User</li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                    <h5 className="text-center">User</h5>
                    <form className="row" autoComplete="off"  onSubmit={this.onSubmitHandle}>
                        <div className="form-group col-md-6">
                        <label>User Name</label>
                            <input type="text"  name="username" value={username} className="form-control" placeholder="User Name" onChange={this.onChangeHandle} />
                            <small>{this.validator.message('user name', this.state.username, 'required', {className : 'text-danger'})}</small>
                        </div>
                        <div className="form-group col-md-6">
                        <label>Email</label>
                            <input type="text" name="email" value={email} className="form-control" placeholder="Email" onChange={this.onChangeHandle} />

                            <small>{this.validator.message('email', email, 'required|email', {className : 'text-danger'})}</small>
                        </div>
                        <div className="form-group col-md-6">
                        <label>Password</label>
                            <input type="password" name="password" value={password} className="form-control" placeholder="Password" onChange={this.onChangeHandle} />
                            {(this.props.match.url !== '/manager/edit') &&
                            <small>{this.validator.message('password', password, 'required', {className : 'text-danger'})}</small>
                            }

                        </div>
                        <div className="form-group col-md-6">
                        <label>Full Name</label>
                            <input type="text" name="fullName" value={fullName} className="form-control" placeholder="Full Name" onChange={this.onChangeHandle} />
                            <small>{this.validator.message('fullName', fullName, 'required', {className : 'text-danger'})}</small>
                        </div>
                       
                        

                        <div className="form-group col-md-12 text-center">
                            <button type="submit"  className="btn btn-info">Submit</button>
                        </div>



                    </form>

                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
        )
    }
}

const mapStateToProps = (state) => ({
    authentication : state.authentication
})

const mapDispatchToProps = dispatch => ({
    addUserData : data => dispatch(addUserData(data)),
    updateUserData : data => dispatch(updateUserData(data))

})

export default connect(mapStateToProps, mapDispatchToProps)(UserData)
