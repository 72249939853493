import { callManagementConstants } from "../constants/ActionTypes";

const initialState = {
  activeLoans: [],
  callhistories: [],
  callhistory: {},
  dispositions: [],
  partnerLoans: [],
  dispActiveLoans: [],
  distBucketClient: [],
  dailycallreports: [],
  isPredictive: 0,
  currentPage: 0,
  perPage: 0,
  totalItems: 0,
  totalPages: 0,
  allDispositions: [],
  profileList: [],
  monthsCalcList: [],
  searchHistory: {},
  calldisposition: {},
  isLast: false,
  allNotifications: [],
  allClients: [],
  managerReports: [],
  allocationReports: [],
  workableReports: [],
  subsType: "",
  additionalReports: [],
  borrowerProfileData: [],
  letterDownload: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case callManagementConstants.GET_ACTIVE_CALL:
      console.log("coming in ActiveLoANS");
      // var ids = new Set(state.activeLoans.map(d => d._id));
      // console.log("payload in reducer",payload.filter(d => !ids.has(d._id)));
      // var merged = [...state.activeLoans, ...payload.filter(d => !ids.has(d._id))];
      // console.log(`merged Arrays `, merged)
      console.log(payload, "activeLoanPayload");
      const updatedPayload = payload.map((item) => ({
        ...item,
        Principal_Outstanding: parseInt(item.Principal_Outstanding), // Assuming it's a valid number
        Total_Due_Amount: parseInt(item.Total_Due_Amount), // Assuming it's a valid number
      }));
      return {
        ...state,
        activeLoans: updatedPayload,
        isLast: payload.length === 0 ? true : false,
      };
    case callManagementConstants.GET_DISP_ACTIVE_CALL:
      console.log("coming in disp", payload);
      return { ...state, dispActiveLoans: payload };
    case callManagementConstants.GET_DIST_CLIENT_BUCKET:
      return { ...state, distBucketClient: payload };
    case callManagementConstants.GET_PARTNER_ALL:
      return { ...state, partnerLoans: payload };

    case callManagementConstants.GET_DISPOSITION_CODE:
      return { ...state, dispositions: payload };

    case callManagementConstants.GET_DISPOSITION_ALL:
      return { ...state, allDispositions: payload };

    case callManagementConstants.GET_ALLOCATION_REPORTS:
      console.log("entering in reducer");
      return { ...state, allocationReports: payload };

    case callManagementConstants.GET_WORKABLE_REPORTS:
      console.log("entering in reducer");
      return { ...state, workableReports: payload };
    case callManagementConstants.subscriptionType:
      console.log("entering in reducer");
      return { ...state, subsType: payload };
    case callManagementConstants.GET_ADDITIONAL_REPORTS:
      console.log("entering in reducer");
      return { ...state, additionalReports: payload };
    case callManagementConstants.GET_CLIENTS_BY_MANAGER:
      return { ...state, allClients: payload };
    case callManagementConstants.CREATE_PROFILE_LIST:
      return { ...state, profileList: payload };
    case callManagementConstants.CREATE_MONTHS_CALC_LIST:
      return { ...state, monthsCalcList: payload };
    case callManagementConstants.GET_MANAGER_REPORTS:
      return { ...state, managerReports: payload };
    case callManagementConstants.BORROWER_PROFILE:
      return { ...state, borrowerProfileData: payload };
    case callManagementConstants.GET_NOTIFICATION_ALL:
      return { ...state, allNotifications: payload };

    case callManagementConstants.GET_LETTER_DOWNLOAD:
      return { ...state, letterDownload: payload };

    case callManagementConstants.GET_REPORTS_ALL:
      return { ...state, allReports: payload };

    case callManagementConstants.DELETE_NOTIFICATION:
      return {
        ...state,
        allNotifications: state.allNotifications.filter(
          (loan) => !payload.includes(loan._id)
        ),
      };

    case callManagementConstants.DELETE_NOTIFICATION_APPROVE:
      return {
        ...state,
        allNotifications: state.allNotifications.filter(
          (loan) => !payload.includes(loan._id)
        ),
      };

    case callManagementConstants.DELETE_CALL_DISPOSITION:
      return {
        ...state,
        callhistories: state.callhistories.filter((s) => s._id !== payload._id),
      };

    case callManagementConstants.GET_CALL_DISPOSITION:
      return { ...state, calldisposition: payload };

    case callManagementConstants.DAILY_CALL_REPORTS:
      return { ...state, dailycallreports: payload };

    case callManagementConstants.PREDICTIVE_CALL_DISPOSTION:
      console.log("reducer inner ", payload);
      return { ...state, isPredictive: payload };

    case callManagementConstants.SEARCH_HISTORY:
      console.log("reducer inner ", payload);
      return { ...state, searchHistory: payload };
    case callManagementConstants.TOATAL_PAGES:
      return { ...state, totalPages: payload };

    case callManagementConstants.TOATAL_ITEMS:
      return { ...state, totalItems: payload };

    case callManagementConstants.CURRENT_PAGE:
      return { ...state, currentPage: payload };

    case callManagementConstants.PER_PAGE:
      return { ...state, perPage: payload };

    case callManagementConstants.CREATE_CALL_STATUS:
      // const aloans = state.searchHistory.activeLoans
      console.log(state.searchHistory, "shPakad");
      console.log(payload, "chupdatedLoan");
      const activeLoans =
        state.searchHistory.activeLoans &&
        state.searchHistory.activeLoans.length > 0
          ? state.searchHistory.activeLoans.map((ch, index) => {
              // return ch
              if (ch._id === payload.loanId) {
                console.log(payload, "chpayload");
                return payload;
              } else {
                console.log(ch, "chchch");
                return ch;
              }
            })
          : [];
      console.log(activeLoans, "errorPakad");
      return {
        ...state,
        searchHistory: {
          ...state.searchHistory,
          activeLoans: activeLoans,
        },
      };
    // let tes = {...state, callhistories : [ ...state.callhistories, ...[payload] ]};
    // console.log(`call reducer`, tes);
    // return {
    //     ...state,
    //     callhistory : payload,
    //     callhistories : [
    //         ...state.callhistories,
    //         ...[payload]
    //     ]

    // }

    case callManagementConstants.UPDATE_CALL_STATUS:
      let callhistories = state.callhistories.map((ch, index) => {
        if (ch._id == payload._id) {
          return payload;
        } else {
          return ch;
        }
      });

      let tess = {
        ...state,
        callhistory: payload,
        callhistories: callhistories,
      };
      console.log(`update call reducer`, tess);

      return {
        ...state,
        callhistories: callhistories,
      };

    case callManagementConstants.GET_CALL_HISTORY:
      return {
        ...state,
        callhistories: Object.keys(payload).length > 0 ? payload : [],
      };

    default:
      return state;
  }
};
