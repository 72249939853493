/* eslint-disable import/no-anonymous-default-export */
import { loanConstants } from "../constants/ActionTypes";
const initialState = {
  loans: [],
  isLast: false,
  pageNum: 0,
  loading: false,
  uploadLoading: false,
  uploadLoans: [],
  matchingLoans: [],
  loanactiveTotal: {},
  loanactivity: [],
  uploadCount: 0,
  loan: {},
  dailycallactivity: {},
  loanHistory: {},
  ruleengineloans: [],
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case loanConstants.GET_ALL_LOANS:
      return {
        ...state,
        loans: Object.values(
          []
            .concat(state.loans, payload)
            .reduce(
              (r, c) => ((r[c._id] = Object.assign(r[c._id] || {}, c)), r),
              {}
            )
        ),
        isLast: payload.length === 0 ? true : false,
      };
    case loanConstants.LOAN_HISTORY:
      return {
        ...state,
        loanHistory: payload,
      };

    case loanConstants.GET_SINGLE_LOAN:
      return {
        ...state,
        loan: payload,
      };
    case loanConstants.GET_SINGLE_STLMNT_LOAN:
      return {
        ...state,
        settlementLoan: payload,
      };

    case loanConstants.LOAN_ACTIVE_TOTAL:
      return {
        ...state,
        loanactiveTotal: payload,
      };
    case loanConstants.LOAN_ACTIVITY:
      console.log(` payload loanactive`, payload);
      return {
        ...state,
        loanactivity: payload,
      };
    case loanConstants.UPLOAD_COUNT:
      console.log(`reducer `, payload);
      return {
        ...state,
        uploadCount: payload,
      };

    case loanConstants.GET_EXTENSION_LOANS:
      return {
        ...state,
        loans: Object.values(
          []
            .concat(state.loans, payload)
            .reduce(
              (r, c) => ((r[c._id] = Object.assign(r[c._id] || {}, c)), r),
              {}
            )
        ),
      };

    case loanConstants.GET_VERIFY_LOANS:
      console.log(`payload `, payload);
      return {
        ...state,
        uploadLoading: false,
        uploadLoans: payload,
      };

    case loanConstants.GET_MATCHING_LOANS:
      console.log(`payload `, payload);
      return {
        ...state,
        matchingLoans: payload,
      };

    case loanConstants.VERIFIED_LOANS:
      return {
        ...state,
        loading: false,
        uploadLoans: [],
      };
    case loanConstants.LOADING_FALSE:
      return { ...state, loading: false };
    case loanConstants.GET_MAXBAR_GRAPH:
      return { ...state, maxBarGraph: payload };
    case loanConstants.GET_MAXBAR_GRAPH:
      return { ...state, minBarGraph: payload };
    case loanConstants.LOAN_REVOKE_EXTEN:
      return { ...state, loading: false };
    case loanConstants.DAILY_CALL_ACTIVITY:
      return {
        ...state,
        dailycallactivity: payload,
      };

    case loanConstants.DELETE_LOAN:
      //             const territoryId = action.data;
      //   return state.filter(territory => territory.id !== territoryId);
      return {
        ...state,
        loans: state.loans.filter((loan) => !payload.includes(loan._id)),
      };

    case loanConstants.DELETE_SHEET:
      return {
        ...state,
        loans: state.loanactivity.filter((sheet) => sheet._id !== payload._id),
      };

    case loanConstants.ADD_LOAN_FILE_UPLOAD:
      console.log(`loands payload`, payload);
      return {
        ...state,
        loading: false,
        loans: [...payload, ...state.loans],
        uploadLoans: [],
      };
    case loanConstants.GET_RULE_ENGINE_LOANS:
      return {
        ...state,
        ruleengineloans: payload,
      };

    case loanConstants.GET_SCHEDULED_LOANS:
      return {
        ...state,
        ptpLoans: payload?.ptpLoans[0] || [],
        callBackLoans: payload?.callBackLoans[0] || [],
      };

    case loanConstants.GET_SCHEDULED_LOANS_ALL:
      return {
        ...state,
        allPtpLoans: payload?.ptpLoans?.length ? payload?.ptpLoans[0] : null,
        allCallbackLoans: payload?.callBackLoans?.length
          ? payload?.callBackLoans[0]
          : null,
      };
    case loanConstants.GET_CLIENTS_LIST:
      return {
        ...state,
        clientList: payload.Clients || [],
      };
    case loanConstants.GET_MANAGER_ANALYTICS:
      return {
        ...state,
        managerAnalytics: payload,
      };
    case loanConstants.GET_MANAGER_PTPs:
      return {
        ...state,
        managerPTPs: payload,
      };
    case loanConstants.GET_LANDING_ANALYTICS:
      return {
        ...state,
        analytics: payload,
      };
    case loanConstants.GET_TARGETS_MANAGER:
      return {
        ...state,
        getTargetsManager: payload,
      };
    case loanConstants.GET_MANAGER_FIELDAGENTS:
      return {
        ...state,
        getManagerFieldsAgents: payload,
      };
    case loanConstants.GET_CLIENT_INCENTIVES_RANGE:
      return {
        ...state,
        getClientsIncentiveRange: payload,
      };
    case loanConstants.GET_FIELD_CASES:
      return {
        ...state,
        fieldCases: payload[0]?.data ? payload[0]?.data : [],
        totalFieldCount: payload[0]?.totalCount
          ? payload[0]?.totalCount[0]?.Loan_ID
          : 0,
      };
    default:
      return state;
  }
};
