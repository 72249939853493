import { API_URL, loanConstants } from "../constants/ActionTypes";
import axios from "axios";
import apiAxios from "./../api";
import { alertActions } from "./alertAction";
import history from "../history";

import { data } from "jquery";
import Cookies from "universal-cookie";
const { REACT_APP_JAVA_API_URL } = process.env;

const defaultOptions = {
  headers: { Authorization: "Bearer " + localStorage.getItem("token") },
};

export const getLoansAll = (page) => async (dispatch) => {
  return await axios
    .get(`${API_URL}/loans?page=${page}`, defaultOptions)
    .then((res) => {
      dispatch({ type: loanConstants.GET_ALL_LOANS, payload: res.data });
      console.log(`loan.length `, res.data.length);
    })
    .catch(handleError);
};
export const getLoansAll2 = (page, client) => async (dispatch) => {
  return await axios
    .get(`${API_URL}/loans/loan2?page=${page}&client=${client}`, defaultOptions)
    .then((res) => {
      dispatch({ type: loanConstants.GET_ALL_LOANS, payload: res.data });
      console.log(`loan.length `, res.data.length);
    })
    .catch(handleError);
};

export const getLoanByManagerId = (managerId) => async (dispatch) => {
  return await axios
    .get(`${API_URL}/loans/by/manager?managerId=${managerId}`, defaultOptions)
    .then((res) => {
      dispatch({ type: loanConstants.GET_ALL_LOANS, payload: res.data });
    })
    .catch(handleError);
};

export const getScheduledLoans =
  (
    ptpPage,
    callbackPage,
    clientName = "",
    startDate,
    endDate,
    cbStartDate,
    cbEndDate,
    collectorId = ""
  ) =>
  async (dispatch) => {
    return await axios
      .get(
        `${API_URL}/loans/scheduledCases?ptpPage=${ptpPage}&callBackPage=${callbackPage}&clientName=${clientName}&collectorId=${collectorId}&ptpStartDate=${startDate}&ptpEndDate=${endDate}&callbackStartDate=${cbStartDate}&callbackEndDate=${cbEndDate}`,
        defaultOptions
      )
      .then((res) => {
        dispatch({
          type: loanConstants.GET_SCHEDULED_LOANS,
          payload: res.data,
        });
      })
      .catch(handleError);
  };
export const getManagerLandingAnalytics =
  (client, collectorId) => async (dispatch) => {
    return await axios
      .get(
        `${API_URL}/loans/getManagerLandingAnalytics?clientsName=${client}&collectorId=${collectorId}`,
        defaultOptions
      )
      .then((res) => {
        dispatch({
          type: loanConstants.GET_MANAGER_ANALYTICS,
          payload: res.data,
        });
      })
      .catch(handleError);
  };

export const getFieldAgentByManager = () => async (dispatch) => {
  return await axios
    .get(`${API_URL}/manager/getFieldAgents`, defaultOptions)
    .then((res) => {
      dispatch({
        type: loanConstants.GET_MANAGER_FIELDAGENTS,
        payload: res.data,
      });
    })
    .catch(handleError);
};

export const assigntoFieldAgent = (data) => () => {
  console.log("assigntoFieldAgent", data);
  return axios
    .put(`${API_URL}/loans/assign/field-agent`, data, defaultOptions)
    .then((res) => {
      alertActions.success("fieldAgent updated successfully");
      // dispatch({type:loanConstants.GET_EXTENSION_LOANS, payload: res.data})
    })
    .catch(handleError);
};
export const getTodaysPtpStockManager =
  (client, collectorId = 0, ptpPage, ptpStartDate, ptpEndDate) =>
  (dispatch) => {
    return axios
      .get(
        `${API_URL}/loans/getTodaysPtpStockManager?clientName=${client}&ptpPage=${ptpPage}&startDate=${ptpStartDate}&endDate=${ptpEndDate}`,
        defaultOptions
      )
      .then((res) => {
        dispatch({ type: loanConstants.GET_MANAGER_PTPs, payload: res.data });
      })
      .catch(handleError);
  };
export const getFieldCases = (fieldPage, clientName) => async (dispatch) => {
  return await axios
    .get(
      `${API_URL}/loans/fieldCases?fieldPage=${fieldPage}&clientName=${clientName}`,
      defaultOptions
    )
    .then((res) => {
      dispatch({ type: loanConstants.GET_FIELD_CASES, payload: res.data });
    })
    .catch(handleError);
};

export const getScheduledLoansAll = (type) => async (dispatch) => {
  let ptp;
  let callback;
  if (type === "ptp") {
    ptp = true;
  }
  if (type === "callback") {
    callback = true;
  }
  return await axios
    .get(
      `${API_URL}/loans/scheduledCasesAll?ptp=${ptp}&callback=${callback}`,
      defaultOptions
    )
    .then((res) => {
      dispatch({
        type: loanConstants.GET_SCHEDULED_LOANS_ALL,
        payload: res.data,
      });
    })
    .catch(handleError);
};

export const getClientList = () => async (dispatch) => {
  return await apiAxios
    .get(`${API_URL}/loans/getLandingClientList`, defaultOptions)
    .then((res) => {
      dispatch({ type: loanConstants.GET_CLIENTS_LIST, payload: res.data });
    })
    .catch(handleError);
};

export const getManagerClientsList = () => async (dispatch) => {
  return await apiAxios
    .get(`${API_URL}/loans/getManagerLandingClientList`, defaultOptions)
    .then((res) => {
      dispatch({ type: loanConstants.GET_CLIENTS_LIST, payload: res.data });
    })
    .catch(handleError);
};
export const getLandingAnalytics =
  (clientName, collectorId = "") =>
  async (dispatch) => {
    return await axios
      .get(
        `${API_URL}/loans/getLandingAnalytics?clientName=${clientName}&collectorId=${collectorId}`,
        defaultOptions
      )
      .then((res) => {
        dispatch({
          type: loanConstants.GET_LANDING_ANALYTICS,
          payload: res.data,
        });
      })
      .catch(handleError);
  };
export const getManagersCollTargets =
  (client, collectorId = "") =>
  async (dispatch) => {
    console.log("collectorId", collectorId);
    return await axios
      .get(
        `${API_URL}/loans/getManagersCollTargets?clientName=${client}&collectorId=${collectorId}`,
        defaultOptions
      )
      .then((res) => {
        console.log("res.data", res.data);
        // res.data.achievement;
        let targAchdata = res.data.achievement.concat(res.data.target);
        let restargAchdata = targAchdata.filter((num) => num !== 0);
        restargAchdata = restargAchdata.sort((a, b) => a - b);
        console.log("restargAchdata", restargAchdata);
        let minBarGraph = restargAchdata[0] - 100;
        let maxBarGraph = restargAchdata[restargAchdata.length - 1] + 1000;
        dispatch({
          type: loanConstants.GET_TARGETS_MANAGER,
          payload: res.data,
        });
        dispatch({
          type: loanConstants.GET_MAXBAR_GRAPH,
          payload: maxBarGraph,
        });
        dispatch({
          type: loanConstants.GET_MINBAR_GRAPH,
          payload: minBarGraph,
        });
      })
      .catch(handleError);
  };

export const getRedirectApi = () => async (dispatch) => {
  //     let email = 'agent1@gmail.com';
  //     let body = {
  //         "source":"collekto",
  //         "auth_secret_key":"a4dd3d3a6csdsdbf94685e089886570baf48",
  //         "redirect_url":"https://collekto.narrator.cloud",
  //         "username":  email
  //     }

  return await axios
    .post(`${API_URL}/redirectBi`, "", defaultOptions)
    .then((res) => {
      console.log("seeted cookie is", res.headers);
      const cookies = new Cookies();
      let cookie = res.data.cookies[0];
      // cookies.set('myCat', 'Pacman', { path: '/' });
      cookie = cookie.split("session=");
      cookie = cookie[1].split(";");
      cookie = cookie[0];
      console.log("new cookie is", cookie);
      cookies.set("session", cookie, { path: "/", domain: "collekto.co.in" });

      console.log("cookies", cookies.get("session"));
      if (
        res.data.subscription_type &&
        res.data.subscription_type == "secondry"
      ) {
        window.location.replace("https://bi.collekto.co.in/app/narrator-view");
      } else {
        window.location.replace("https://bi.collekto.co.in/app/narrator");
      }
      // Cookies.set(Response.data)
    });
};

export const getSettlementLoanByManagerId = (managerId) => async (dispatch) => {
  return await axios
    .get(
      `${API_URL}/settlement/byManagerId?managerId=${managerId}`,
      defaultOptions
    )
    .then((res) => {
      dispatch({ type: loanConstants.GET_ALL_LOANS, payload: res.data });
    })
    .catch(handleError);
};

export const getSettlementLoanBYClientId = (clientId) => (dispatch) => {
  return axios
    .get(
      `${API_URL}/settlement/byClientId?clientId=${clientId}`,
      defaultOptions
    )
    .then((res) => {
      dispatch({ type: loanConstants.GET_ALL_LOANS, payload: res.data });
    })
    .catch(handleError);
};

export const getDailyCallStatus =
  (collectorId, managerId, clientName, gte, lte) => async (dispatch) => {
    const cId = collectorId ? `collectorId=${collectorId}` : "";
    const mId = managerId ? `&managerId=${managerId}` : "";
    const clId = clientName ? `&clientName=${clientName}` : "";
    const pgte = gte ? `&gte=${gte}` : "";
    const plte = lte ? `&lte=${lte}` : "";

    console.log(`plte `, plte);
    console.log(`lte `, lte);

    //const mId = (managerId)?`?managerId=${managerId}`:''

    console.log(
      `${API_URL}/activity/daily-call/status?${cId}${mId}${pgte}${plte}`
    );
    return await axios
      .get(
        `${API_URL}/activity/daily-call/status?${cId}${mId}${pgte}${plte}${clId}`,
        defaultOptions
      )
      .then((res) => {
        console.log(`res.data `, res.data);
        dispatch({
          type: loanConstants.DAILY_CALL_ACTIVITY,
          payload: res.data,
        });
      })
      .catch(handleError);
  };

export const getFieldAgentStatus = (fieldAgentId) => async (dispatch) => {
  const cId = fieldAgentId ? `?fieldAgentId=${fieldAgentId}` : "";
  console.log(`activity call`);
  return await axios
    .get(`${API_URL}/activity/daily-field-agent/status${cId}`, defaultOptions)
    .then((res) => {
      console.log(`res.data `, res.data);
      dispatch({ type: loanConstants.DAILY_CALL_ACTIVITY, payload: res.data });
    })
    .catch(handleError);
};

export const getLoanById = (id) => async (dispatch) => {
  console.log(id,"loanidid");
  return await axios
    .get(`${API_URL}/loans/id/${id}`, defaultOptions)
    .then((res) => {
      dispatch({ type: loanConstants.GET_SINGLE_LOAN, payload: res.data });
    })
    .catch(handleError);
};
export const getSettlementLoanById = (id) => async (dispatch) => {
  return await axios
    .get(`${API_URL}/settlement/id/${id}`, defaultOptions)
    .then((res) => {
      dispatch({
        type: loanConstants.GET_SINGLE_STLMNT_LOAN,
        payload: res.data,
      });
    })
    .catch(handleError);
};
export const loanActive = () => (dispatch) => {
  console.log(`loanActive`);
  return axios
    .get(`${API_URL}/activity/loan/all`, defaultOptions)
    .then((res) => {
      console.log("res.data action", res.data);
      dispatch({ type: loanConstants.LOAN_ACTIVITY, payload: res.data });
    })
    .catch(handleError);
};

export const updateLoanBySheet = (data) => async (dispatch) => {
  console.log(`updateLoanBySheet`);
  return await axios
    .put(`${API_URL}/activity/loan/sheet`, data, defaultOptions)
    .then((res) => {
      console.log("res.data action", res.data);
      alertActions.success("loan updated successfully");
      dispatch({ type: loanConstants.LOAN_REVOKE_EXTEN, payload: {} });
    })
    .catch((error) => {
      dispatch({ type: loanConstants.LOADING_FALSE });
      handleError(error);
    });
};

export const deleteSheet = (sheet) => async (dispatch) => {
  console.log(`deleteSheet`);
  return await axios
    .delete(API_URL + "/activity/delete-loan/by/sheet", {
      data: sheet,
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
    .then((res) => {
      dispatch({
        type: loanConstants.DELETE_SHEET,
        payload: { _id: sheet._id },
      });
      alertActions.success("Sheet deleted successfully");
    });
};

export const loanactiveTotal = () => async (dispatch) => {
  console.log(`loanactiveTotal`);
  return await axios
    .get(`${API_URL}/loans/total/assigned`, defaultOptions)
    .then((res) => {
      console.log(`loanactiveTotal res.data `, res.data);
      dispatch({ type: loanConstants.LOAN_ACTIVE_TOTAL, payload: res.data });
    })
    .catch(handleError);
};

export const getLastUploadBatchNo = () => async (dispatch) => {
  console.log(`getLastUploadBatchNo`);
  return await axios
    .get(`${API_URL}/loans/last/upload/batch-no`, defaultOptions)
    .then((res) => {
      console.log(`getLastUploadBatchNo`, res.data);

      dispatch({
        type: loanConstants.UPLOAD_COUNT,
        payload: res.data.uploadCount,
      });
    })
    .catch(handleError);
};

export const getLoanFilterKey = (bank) => async (dispatch) => {
  return await axios
    .get(
      `${API_URL}/loans/filter?bank=${bank}&&attributes="Product_type,Due_Amt_of_Total_Loan,DPD,Stage,loan_amount,EMI_total_due_amount,Overdue_principle_due_amount,total_penality_due,TC,Short_Code,PTP_Amt,Principal_PAID_Amount,Penal_PAID_Amount,Connectivity_Score,POS,TOS"`,
      defaultOptions
    )
    .then((res) => {
      dispatch({ type: loanConstants.GET_ALL_LOANS, payload: res.data });
    })
    .catch(handleError);
};

export const getLoansextend = (page) => async (dispatch) => {
  console.log(`page `, page);
  return await axios
    .get(`${API_URL}/loans?page=${page}`, defaultOptions)
    .then((res) => {
      console.log(`loan extends `, res.data);
      dispatch({ type: loanConstants.GET_EXTENSION_LOANS, payload: res.data });
    })
    .catch(handleError);
};

export const deleteLoan = (loanIds) => {
  console.log("deleteLoan,", defaultOptions);
  return async (dispatch) => {
    return await axios
      .delete(API_URL + "/loans", {
        data: loanIds,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
      .then((res) => {
        dispatch({ type: loanConstants.DELETE_LOAN, payload: loanIds });
        alertActions.success("Loan deleted successfully");
      });
  };
};

export const csvUpload = (data) => async (dispatch) => {
  const formData = new FormData();
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      formData.append([key], data[key]);
    }
  }
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };
  return await axios
    .post(`${API_URL}/loans/csv/upload`, formData, config)
    .then((res) => {
      alertActions.success("Loan sheet uploaded successfully");
      // history.push(`/`)
      dispatch({ type: loanConstants.GET_VERIFY_LOANS, payload: res.data });
    })
    .catch(handleError);
};

export const mappingUploadFile = (data) => async (dispatch) => {
  const formData = new FormData();
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      formData.append([key], data[key]);
    }
  }
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };
  return await axios
    .post(`${API_URL}/loans/mapping/upload`, formData, config)
    .then((res) => {
      alertActions.success("Loan sheet uploaded successfully");
      // history.push(`/`)
      console.log(`res.data `, res.data);
      dispatch({ type: loanConstants.GET_VERIFY_LOANS, payload: res.data });
    })
    .catch(handleError);
};

export const verifyCsv = (data) => async (dispatch) => {
  return await axios
    .put(`${API_URL}/loans/csv/verify`, data, defaultOptions)
    .then((res) => {
      console.log(`res.data `, res.data);
      alertActions.success("Loan sheet verified successfully");
      history.push(`/loans`);
      dispatch({ type: loanConstants.VERIFIED_LOANS, payload: res.data });
    })
    .catch(handleError);
};

export const verifyBankPaid = (data) => async (dispatch) => {
  return await axios
    .post(`${API_URL}/loans/bankpaid/verify`, data, defaultOptions)
    .then((res) => {
      console.log(`res.data `, res.data);
      alertActions.success("bank paid sheet verified successfully");
      history.push(`/loans`);
      dispatch({ type: loanConstants.VERIFIED_LOANS, payload: res.data });
    })
    .catch(handleError);
};

export const getLoanByLoanIds = (data) => async (dispatch) => {
  return await axios
    .post(`${API_URL}/loans/matching-loanIds`, data, defaultOptions)
    .then((res) => {
      console.log(`res.data `, res.data);
      //  alertActions.success('bank paid sheet verified successfully')
      //  history.push(`/loans`)
      dispatch({ type: loanConstants.GET_MATCHING_LOANS, payload: res.data });
    })
    .catch(handleError);
};

export const loanManualEntry = (data) => async (dispatch) => {
  return await axios
    .put(`${API_URL}/loans/manual/entry`, data, defaultOptions)
    .then((res) => {
      if (res.data.errors) {
        alertActions.error(res.data.error);
      } else {
        history.push(`/loans`);
        alertActions.success("Loan record added successfully");
        dispatch({ type: loanConstants.VERIFIED_LOANS, payload: res.data });
      }
    })
    .catch((error) => {
      dispatch({ type: loanConstants.LOADING_FALSE });
      handleError(error);
    });
};

export const updateAssignByCollector = (data) => async (dispatch) => {
  data = data.map((el) => {
    return {
      collectorId: el.collectorId,
      _id: el._id,
    };
  });
  return await axios
    .put(`${API_URL}/loans/assign/collector`, data, defaultOptions)
    .then((res) => {
      alertActions.success("collector updated successfully");
      console.log(`res.data`, res.data);
      // axios.get(`${API_URL}/loans`).then(ress => {
      dispatch({ type: loanConstants.GET_EXTENSION_LOANS, payload: res.data });
      // }).catch(handleError)

      // axios.get(`${API_URL}/loans/total/assigned`).then(res => {
      //     console.log(`loanactiveTotal res.data `, res.data)
      // dispatch({type : loanConstants.LOAN_ACTIVE_TOTAL, payload : res.data})
      // }).catch(handleError)
    })
    .catch(handleError);
};

export const updateAssignByFieldAgent = (data) => async (dispatch) => {
  data = data.map((el) => {
    return {
      _id: el._id,
      fieldAgentId: el.fieldAgentId,
      visitDate: el.visitDate,
    };
  });
  console.log(`loan action data `, data);
  return await axios
    .put(`${API_URL}/loans/assign/field-agent`, data, defaultOptions)
    .then((res) => {
      alertActions.success("field agent updated successfully");
      dispatch({ type: loanConstants.GET_EXTENSION_LOANS, payload: res.data });
    })
    .catch(handleError);
};

export const updateAssignByManager = (data) => async (dispatch) => {
  data = data.map((el) => {
    return {
      managerId: el.managerId,
      _id: el._id,
    };
  });
  console.log(`loan action data `, data);
  return await axios
    .put(`${API_URL}/loans/assign/manager`, data, defaultOptions)
    .then((res) => {
      alertActions.success("Manager updated successfully");
      dispatch({ type: loanConstants.GET_EXTENSION_LOANS, payload: res.data });
    })
    .catch(handleError);
};

export const uploadAllocationRuleFile = (data) => async (dispatch) => {
  const formData = new FormData();
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      formData.append([key], data[key]);
    }
  }
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };
  return await axios
    .post(`${API_URL}/loans/allocation-rule/upload`, formData, config)
    .then((res) => {
      alertActions.success("Loan sheet uploaded successfully");
      console.log(`res.data `, res.data);
      dispatch({
        type: loanConstants.GET_RULE_ENGINE_LOANS,
        payload: res.data,
      });
    })
    .catch(handleError);
};

// export const loanFileUpload = (data) => dispatch => {
//     const formData = new FormData()
//     for (const key in data) {
//         if (data.hasOwnProperty(key)) {
//             formData.append([key], data[key]);
//         }
//     }
//     const config = {
//         headers: {
//             'Content-Type': 'multipart/form-data'
//         }
//     }
//     return axios.put(`${API_URL}/loans/multiple`, formData, config).then(res => {

//        //
//         alertActions.success('Loan sheet uploaded successfully')
//         // history.push(`/`)

//     }).catch(handleError)

// }

export const addLoans = (data) => async (dispatch) => {
  return await axios
    .post(`${API_URL}/loans`, data, defaultOptions)
    .then((res) => {
      // dispatch({type:loanConstants.ADD_LOANS, payload: res.data})
    })
    .catch(handleError);
};

export const handleError = (error) => {
  console.log(`error `, error);
  alertActions.error(error);
};

export const getClientincentivesTarget =
  (Client_Name, roleType) => async (dispatch) => {
    return await axios
      .get(
        `${REACT_APP_JAVA_API_URL}/api/v2/incentives?clientId=${Client_Name}&roleType=${roleType}`
      )
      .then((res) => {
        dispatch({
          type: loanConstants.GET_CLIENT_INCENTIVES_RANGE,
          payload: res.data?.data[0] ? res.data.data[0]?.incentives : [],
        });
      })
      .catch(handleError);
  };
