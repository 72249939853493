import React, {Component} from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import "./Notifications/notification.css";
import "./../AssignedLoan/CallManagement.module.scss";
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter";
const {SearchBar} = Search;


function onPageChange(page, sizePerPage) {
    console.log(page, sizePerPage);
}

function onSizePerPageChange(sizePerPage, page) {
    console.log(sizePerPage, page);
}

function customTotal(from, to, size) {
    return (
      <div className="col-3 d-flex justify-content-start">
        <span className="react-bootstrap-table-pagination-total">
          <span style={{ color: "#575757", fontWeight:"normal" , fontSize:"16px" }}>Showing </span>
          <span style={{ fontWeight: "bold", color: "#00549E" , fontSize:"16px"}}>{to} </span>
          <span style={{ color: "#575757", fontWeight:"normal", fontSize:"16px" }}>of </span>
          <span style={{ fontWeight: "bold", color: "#575757", fontSize:"16px" }}>{size} </span>
        </span>
      </div>
    );
  }

const options = {
    paginationSize: 3,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button
    hideSizePerPage: true, // Hide the sizePerPage dropdown
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "🡰",
    nextPageText: "🡲",
    lastPageText: "Last",
    sizePerPage: 10,
    showTotal: true,
    paginationTotalRenderer: customTotal, // Custom renderer for total
    onPageChange: onPageChange,
    onSizePerPageChange: onSizePerPageChange,
    pageButtonRenderer: (props) => {
      console.log("Buttons");
      const { page, active, disabled, title, onPageChange } = props;
      const handleClick = (e) => {
        e.preventDefault();
        onPageChange(page);
      };
      const buttonClass = active ? "selected" : "unselected";
      return (
        <div className="mr-1">
          <button
            className={buttonClass}
            style={{
              borderColor: "#E0E0E0",
              color: active ? "#fff" : "#777373",
              borderRadius: "7px",
              fontWeight: "bold",
              backgroundColor: active ? "#00549E" : "#fff",
              width: "30px"
            }}
            onClick={handleClick}
            disabled={disabled}
            title={title}
          >
            {page}
          </button>
        </div>
      );
    },
  };

function rowStyleFormat(row, rowIdx) {
    return {
      backgroundColor: rowIdx % 2 === 0 ? "#000000" : "#c4c4c4",
    };
  }

export default class Table extends Component{
    render(){
      let {missedAlerts,  alertCols, rowEvents} = this.props;
      console.log("rowEvents actually are",rowEvents)
      missedAlerts = missedAlerts?.length? missedAlerts : [];
      alertCols = alertCols?.length? alertCols : [];
        return(
            <div className="row">
            <div className="col-lg-12 p-0">
              <React.Fragment>
                <div className="collektoTable active-loan-table">
                  <ToolkitProvider
                    className="pull-right"
                    data={missedAlerts}
                    columns={alertCols?.length? alertCols : [{dataField: "xyz", text: "No columns Available"}]}
                    search
                    remote={true}
                    keyField="loan_id"
                  >
                    {(props) => (
                      <div className="action_loanFullwidth">
                        <div
                          style={{
                            backgroundColor: "#fff",
                            width: "100%",
                            height: "100%",
                            borderRadius: "7px",
                          }}
                          className="col-md-12  pt-3 pl-3 pb-3">
                          <BootstrapTable
                            title="view"
                            rowEvents={rowEvents}
                            headerClasses="CustomClass shadow-lg p-3 mb-5 rounded"
                            bodyClasses="CustomBodyClass"
                          
                            TrStyle={rowStyleFormat}
                            rowStyle={{
                              cursor: "pointer",
                              border: "none",
                            }}
                            pagination={paginationFactory(options)}
                            bootstrap4
                            bordered = {false}
                            className=""
                            striped
                            condensed
                            responsive
                            data={[]}
                            columns={alertCols}
                            keyField="loan_id"
                            {...props.baseProps}
                            filter={filterFactory()}
                            noDataIndication="No Data Found"
                          />
                        </div>
                      </div>
                    )}
                  </ToolkitProvider>
                </div>
              </React.Fragment>
            </div>
          </div>
        )
    }
}