import Axios from "axios"
import { API_URL, managerConstants } from "../constants/ActionTypes"
import { alertActions } from "./alertAction"
import history from "../history"

const { REACT_APP_JAVA_API_URL } = process.env;
const defaultOptions = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token'), } };

const collektoUser = JSON.parse(localStorage.getItem("collektoUser"));
export const getManagerAll = (createdBy) => dispatch => {
    console.log(`getManagerAll`)
    return Axios.get(`${API_URL}/manager/all?createdBy=${createdBy}`, defaultOptions).then(res => {
        dispatch({ type: managerConstants.GET_MANAGER_ALL, payload: res.data })
        console.log(`managers res.data `, res.data);
    }).catch(handleError)
}


export const addManager = (data) => dispatch => {
    // console.log(`getManagerAll`)
    return Axios.post(`${API_URL}/manager`, data, defaultOptions).then(res => {
        // dispatch({type : managerConstants.ADD_MANAGER, payload : res.data})
        history.push(`/managers-collectors`)
    }).catch(handleError)
}

export const addUserData = (data) => dispatch => {
    // console.log(`getManagerAll`)
    console.log(`add user data `, data)
    return Axios.post(`${API_URL}/userData`, data, defaultOptions).then(res => {
        console.log(`res user data `, res.data)
        // dispatch({type : managerConstants.ADD_MANAGER, payload : res.data})
        history.push(`/userdata`)
    }).catch(handleError)
}


export const updateUserData = (data) => dispatch => {
    // console.log(`getManagerAll`)
    console.log(`update user data `, data)
    return Axios.put(`${API_URL}/userData`, data, defaultOptions).then(res => {
        console.log(`update res user data `, res.data)
        // dispatch({type : managerConstants.ADD_MANAGER, payload : res.data})
        history.push(`/userData`)
    }).catch(handleError)
}

export const updateManager = (data) => dispatch => {
    // console.log(`getManagerAll`)
    return Axios.put(`${API_URL}/manager`, data, defaultOptions).then(res => {
        // dispatch({type : managerConstants.ADD_MANAGER, payload : res.data})
        history.push(`/managers-collectors`)
    }).catch(handleError)
}

export const deleteManager = (data) => {
    return (dispatch) => {
        return Axios.delete(`${API_URL}/manager/${data?._id}`, defaultOptions)
            .then((res) => {
                dispatch({ type: managerConstants.DELETE_MANAGER, payload: data })
                // history.push(`/managers-collectors`)
                alertActions.success('Sheet deleted successfully')
            });
    }
}

export const getManagerNotificationCount = () => dispatch => {
    const options = { headers: { ...defaultOptions.headers, managerId: collektoUser?._id } }
    Axios.get(`${REACT_APP_JAVA_API_URL}/api/v2/notifications/count`, options).then(({ data }) => {
        const count = data?.data?.notificationCount;
        dispatch({ type: managerConstants.GET_NOTIFICATION_COUNT, payload: { count } });
    }).catch(handleError);
}
export const handleError = (error) => {
    console.log(`error `, error)
    alertActions.error(error);
}