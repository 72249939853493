import { partnerConstants } from "../constants/ActionTypes"

const initialState = {
    partners: []
}

export default (state = initialState, { type, payload }) => {
    switch (type) {

    case partnerConstants.GET_PARTNER_ALL:
        return { ...state, partners : payload }

    default:
        return state
    }
}
