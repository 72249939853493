import { fieldAgentConstants } from "../constants/ActionTypes"

const initialState = {
    fieldagentloans: [],
    fieldagents: [],
    ruleLoans: [],
    isLast: false
}

export default (state = initialState, { type, payload }) => {
    switch (type) {

    case fieldAgentConstants.GET_FIELD_AGENT_LOANS:
        return { ...state, fieldagentloans : payload }

    case fieldAgentConstants.UPDATE_FIELD_AGENT_LOAN:
        return { ...state, fieldagentloans : state.fieldagentloans.map(f => { return (f._id === payload._id)?payload:f }) 
    }

    case fieldAgentConstants.GET_FIELD_AGENT_ALL:
        return { ...state, fieldagents : payload }

    case fieldAgentConstants.GET_ALLOCATION_RULE_LOANS:
        return { ...state, ruleLoans : payload, isLast: (payload.length === 0)?true:false }

        case fieldAgentConstants.DELETE_FIELD_AGENT:
            return { ...state,
                fieldagents : state.fieldagents.filter(dv => (dv._id !== payload._id))
            }

    default:
        return state
    }
}
