import React, { Fragment } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Header from './components/common/Header';
export const AgentRoute = ({ component: Component,  roles, ...rest }) => {
    let currentUser = JSON.parse(localStorage.getItem('collektoUser'));
    console.log(`currentUser `, currentUser)
    let isHeader = localStorage.getItem('collektoUser')?<Header /> : null;
    return (
        <React.Fragment>
            {isHeader}
        <Route {...rest} render={props => (
            localStorage.getItem('collektoUser')
                ?
                <Fragment>
                {(roles && roles.indexOf(currentUser.role) === -1) &&
            <Redirect to={{ pathname: '/'}} />
        }
                <Component {...props} />
                    </Fragment>

                : <Redirect to={{ pathname: '/clientlogin', state: { from: props.location } }} />
        )} />
        </React.Fragment>
    )
}

