// api.js

import axios from 'axios';
import { logout } from './actions';
import { connect, useDispatch } from 'react-redux';
import store from "./store/configureStore";
import { Dispatch } from 'react';
// Create an instance of axios
const apiAxios = axios.create();
// Add a response interceptor to the axios instance

apiAxios.interceptors.response.use(
    response => {
        // Return the response if it's successful (status code 200-299)
        return response;
    },
    error => {
        // Handle error responses here
        if (error.response && error.response.status === 401) {
            // Call your specific function when the API returns 400 status code
            handleBadRequest();
        }
        // You can also handle other status codes here if needed
        // For example, you might want to redirect to an error page for 404 status
        // or show a general error message for other status codes.

        // Throw the error to propagate it down the promise chain
        return Promise.reject(error);
    }
);

// Your specific function to handle 400 status code
const handleBadRequest = () => {
    console.log('API returned 401 status code');
    // Dispatch the 'logout' action using the Redux 'dispatch' function
    store.dispatch(logout());
  };

export default apiAxios;
