import { portfolioConstants } from "../constants/ActionTypes"

const initialState = {
    portfolios: []
}

export default (state = initialState, { type, payload }) => {
    switch (type) {

    case portfolioConstants.GET_PORTFOLIO_ALL:
        return { ...state, portfolios : payload }

    default:
        return state
    }
}
