import { adminConstants, API_URL } from "../constants/ActionTypes";
import { alertActions } from "./alertAction";
import axios from "axios";
import history from "../history";
import { NotificationManager } from "react-notifications";
// import api

const defaultOptions = {
  headers: { Authorization: "Bearer " + localStorage.getItem("token") },
};
const { REACT_APP_API_URL, REACT_APP_DIALER_URL, REACT_APP_DIALER_APP } =
  process.env;
export const getAdmins = () => (dispatch) => {
  return fetch(API_URL + "/admin")
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      console.log("admins actions ", result);
      dispatch({
        type: adminConstants.GET_ADMINS,
        payload: result.admins,
      });
    });
};

// export const getMyProfileById = (id) => dispatch => {
//     console.log(`getCollectorAll`)
//     return axios.get(`${API_URL}/admin/id?id=${id}`).then(res => {
//         dispatch({type : adminConstants.MY_PROFILE, payload : res.data})
//     }).catch(handleError)
// }
export const getMyProfileById = (id) => (dispatch) => {
  return axios
    .get(`${API_URL}/admin/id/${id}`, defaultOptions)
    .then((res) => {
      dispatch({
        type: adminConstants.MY_PROFILE,
        payload: res.data,
      });
      console.log(`admin by id `, res.data);
    })
    .catch((error) => {
      handleError(error);
    });
};

export const login = (data) => (dispatch) => {
  return axios
    .post(`${API_URL}/admin/login`, data)
    .then((res) => {
      console.log("token res ", res.data);

      if (res.data.auth === true) {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("collektoUser", JSON.stringify(res.data.userInfo));
        history.go(0);
        dispatch({
          type: adminConstants.LOGIN_SUCCESS,
          collektoUser: res.data.userInfo,
        });
        // const defaultOptions = { headers: { 'x-access-token': res.data.token, } };
        // return axios.get(API_URL+'/admin/auth/me', defaultOptions)
        //     .then((response) => {
        //         console.log("user info response ", response.data);
        //         localStorage.setItem('collektoUser', JSON.stringify(response.data))
        //         dispatch({ type: adminConstants.LOGIN_SUCCESS, collektoUser: response.data })

        //     });

        history.push(`/`);
      } else {
        console.log("res ", res);
        alertActions.error(res.data);
        // const { isDevice } = res.data
        dispatch({ type: adminConstants.LOGIN_FAILURE, collektoUser: data });
      }
    })
    .catch((error) => {
      dispatch({ type: adminConstants.LOGIN_FAILURE, collektoUser: data });
      handleError(error);
    });
};

export const getDashboardValue = () => (dispatch) => {
  let currentUser = JSON.parse(localStorage.getItem("collektoUser"));

  return axios
    .get(
      `${API_URL}/data-visualization/by/category?category=${currentUser.role}`,
      defaultOptions
    )
    .then((dash) => {
      dispatch({ type: adminConstants.DASHBOARD, payload: dash.data });
    });
};

export const commonLogin = (data) => (dispatch) => {
  if (data?.role === "dialer") {
    const { email, password } = data;
    axios
      .post(`${REACT_APP_DIALER_URL}/auth/login`, {
        username: email,
        password: password,
      })
      .then((res) => {
        console.log(
          "login API:",
          res.data,
          `${REACT_APP_DIALER_APP}/crmLogin/${res.data}`
        );
        if (res.data) {
          localStorage.setItem("role", "dialer");
          localStorage.setItem("token", res.data);
          setTimeout(() => {
            window.location.href = `${REACT_APP_DIALER_APP}/crmLogin/${res.data}`;
          });
        } else {
          NotificationManager.error(`${res.data}`);
        }
      })
      .catch((err) => {
        console.log("err.response: ", err.response);
        NotificationManager.error(`${err.response.data}`);
        // dispatch({ type: adminConstants.LOGIN_FAILURE, collektoUser: data });
        // handleError(err);
      });
  } else {
    return axios
      .post(`${API_URL}/login`, data)
      .then((res) => {
        console.log("token res ", res.data);
        if (res.data.auth === true) {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("role", data?.role);
          localStorage.setItem(
            "collektoUser",
            JSON.stringify(res.data.userInfo)
          );
          dispatch({
            type: adminConstants.LOGIN_SUCCESS,
            collektoUser: res.data.userInfo,
          });
          history.go(0);
        } else {
          console.log("res ", res);
          alertActions.error(res.data);
          dispatch({ type: adminConstants.LOGIN_FAILURE, collektoUser: data });
        }
      })
      .catch((error) => {
        dispatch({ type: adminConstants.LOGIN_FAILURE, collektoUser: data });
        handleError({ ...error, message: error?.message });
      });
  }
};
const { REACT_APP_JAVA_API_URL } = process.env;

export const collectorLogin = (data) => (dispatch) => {
  return axios
    .post(`${API_URL}/collector/login`, data)
    .then((res) => {
      console.log("token res ", res.data);
      if (res.data.auth === true) {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("collektoUser", JSON.stringify(res.data.userInfo));
        dispatch({
          type: adminConstants.LOGIN_SUCCESS,
          collektoUser: res.data.userInfo,
        });
        history.go(0);
      } else {
        console.log("res ", res);
        alertActions.error(res.data);
        dispatch({ type: adminConstants.LOGIN_FAILURE, collektoUser: data });
      }
    })
    .catch((error) => {
      dispatch({ type: adminConstants.LOGIN_FAILURE, collektoUser: data });
      handleError(error);
    });
};
export const getMaProfileById = (id) => (dispatch) => {
  return axios
    .get(`${API_URL}/manager/id/${id}`, defaultOptions)
    .then((res) => {
      dispatch({
        type: adminConstants.MY_PROFILE,
        payload: res.data,
      });
      console.log(`collector rest `, res.data);
    })
    .catch((error) => {
      handleError(error);
    });
};
export const updateMaProfile = (manager) => {
  console.log("udpate manager ", manager);
  return (dispatch) => {
    return axios
      .put(API_URL + "/manager", manager, defaultOptions)
      .then((res) => {
        console.log("response manager", res);

        history.push(`/`);
      });
  };
};
export const getCollectorProfileById = (id) => (dispatch) => {
  return axios
    .get(`${API_URL}/collector/id/${id}`, defaultOptions)
    .then((res) => {
      dispatch({
        type: adminConstants.MY_PROFILE,
        payload: res.data,
      });
      console.log(`collector rest `, res.data);
    })
    .catch((error) => {
      handleError(error);
    });
};
export const updateCollectorProfile = (collector) => {
  console.log("udpate collector ", collector);
  return (dispatch) => {
    return axios
      .put(API_URL + "/collector", collector, defaultOptions)
      .then((res) => {
        console.log("response collector", res);

        history.push(`/`);
      });
  };
};
// export const agentLogin = (data) => dispatch => {
//     return axios.post(`${API_URL}/collector/login`, data).then(res => {
//         console.log("token res ", res.data);
//                 if(res.data.auth === true){
//                     localStorage.setItem('token', res.data.token)
//                     const defaultOptions = { headers: { 'x-access-token': res.data.token, } };
//                         return axios.get(API_URL+'/collector/auth/me', defaultOptions)
//                             .then((response) => {
//                                 console.log("user info response ", response.data);
//                                 localStorage.setItem('collektoUser', JSON.stringify(response.data))
//                                 dispatch({ type: adminConstants.LOGIN_SUCCESS, collektoUser: response.data })
//                                 history.push(`/`)
//                             });

//                 }else{
//                     console.log('res ',  res)
//                     alertActions.error(res.data)
//                     dispatch({ type: adminConstants.LOGIN_FAILURE, collektoUser:  data})
//                 }
//             }).catch(error => {
//                 dispatch({ type: adminConstants.LOGIN_FAILURE, collektoUser:  data})
//                 handleError(error)
//             })
// }

export const customerLogin = (data) => (dispatch) => {
  return axios
    .post(`${API_URL}/client/login`, data)
    .then((res) => {
      console.log("token res ", res.data);
      if (res.data.auth === true) {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("collektoUser", JSON.stringify(res.data.userInfo));
        history.go(0);
        dispatch({
          type: adminConstants.LOGIN_SUCCESS,
          collektoUser: res.data.userInfo,
        });
        history.push(`/`);
      } else {
        console.log("res ", res);
        alertActions.error(res.data);
        dispatch({ type: adminConstants.LOGIN_FAILURE, collektoUser: data });
      }
    })
    .catch((error) => {
      dispatch({ type: adminConstants.LOGIN_FAILURE, collektoUser: data });
      handleError(error);
    });
};

export const partnerLogin = (data) => (dispatch) => {
  return axios
    .post(`${API_URL}/partner/login`, data)
    .then((res) => {
      console.log("token res ", res.data);
      if (res.data.auth === true) {
        localStorage.setItem("token", res.data.token);
        history.go(0);
        const defaultOptions = {
          headers: { "x-access-token": res.data.token },
        };
        return axios
          .get(API_URL + "/partner/auth/me", defaultOptions)
          .then((response) => {
            console.log("user info response ", response.data);
            localStorage.setItem("collektoUser", JSON.stringify(response.data));
            dispatch({
              type: adminConstants.LOGIN_SUCCESS,
              collektoUser: response.data,
            });
            history.push(`/`);
          });
      } else {
        console.log("res ", res);
        alertActions.error(res.data);
        dispatch({ type: adminConstants.LOGIN_FAILURE, collektoUser: data });
      }
    })
    .catch((error) => {
      dispatch({ type: adminConstants.LOGIN_FAILURE, collektoUser: data });
      handleError(error);
    });
};

export const managerLogin = (data) => (dispatch) => {
  return axios
    .post(`${API_URL}/manager/login`, data)
    .then((res) => {
      console.log("token res ", res.data);
      if (res.data.auth === true) {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("collektoUser", JSON.stringify(res.data.userInfo));
        history.go(0);
        dispatch({
          type: adminConstants.LOGIN_SUCCESS,
          collektoUser: res.data.userInfo,
        });
        history.push(`/`);
      } else {
        console.log("res ", res);
        alertActions.error(res.data);
        dispatch({ type: adminConstants.LOGIN_FAILURE, collektoUser: data });
      }
    })
    .catch((error) => {
      dispatch({ type: adminConstants.LOGIN_FAILURE, collektoUser: data });
      handleError(error);
    });

  // return axios.post(`${API_URL}/manager/login`, data).then(res => {
  //     console.log("token res ", res.data);
  //             if(res.data.auth === true){
  //                 localStorage.setItem('token', res.data.token)
  //                 const defaultOptions = { headers: { 'x-access-token': res.data.token, } };
  //                     return axios.get(API_URL+'/manager/auth/me', defaultOptions)
  //                         .then((response) => {
  //                             console.log("user info response ", response.data);
  //                             localStorage.setItem('collektoUser', JSON.stringify(response.data))
  //                             dispatch({ type: adminConstants.LOGIN_SUCCESS, collektoUser: response.data })
  //                             history.push(`/`)
  //                         });

  //             }else{
  //                 console.log('res ',  res)
  //                 alertActions.error(res.data)
  //                 dispatch({ type: adminConstants.LOGIN_FAILURE, collektoUser:  data})
  //             }
  //         }).catch(error => {
  //             dispatch({ type: adminConstants.LOGIN_FAILURE, collektoUser:  data})
  //             handleError(error)
  //         })
};

export const logout = (roless) => {
  console.log(`localStorage.getItem('token') `, localStorage.getItem("token"));
  const defaultOptions = {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  };
  let currentUser = JSON.parse(localStorage.getItem("collektoUser"));
  axios.post(`${REACT_APP_JAVA_API_URL}/api/v2/dialer/logout`, {
    username: currentUser.username,
  });
  // let role = currentUser.role
  // const { role } = currentUser
  console.log(`currentUser `, currentUser);
  console.log(`document.location.pathname `, document.location.pathname);
  // console.log(`role `, role)
  return (dispatch) => {
    dispatch({ type: adminConstants.LOGOUT });
    if (currentUser && currentUser?.role == "COLLECTOR") {
      const url = `${REACT_APP_API_URL}/collector/auth/logout`;
      axios
        .get(url, defaultOptions)
        .then(() => {
          history.push(`/login`);
        })
        .catch(handleError);
    } else if (currentUser && currentUser?.role == "PARTNER") {
      history.push(`/login`);
    } else if (currentUser && currentUser?.role == "CUSTOMER") {
      history.push(`/login`);
    } else if (currentUser && currentUser?.role == "MANAGER") {
      // axios.post(API_URL+'/manager/auth/logoutall', {}, defaultOptions).then((response) => {
      //     console.log("manager info response ", response.data);
      // });
      history.push(`/login`);
    } else if (currentUser && currentUser?.role == "FIELDAGENT") {
      history.push(`/login`);
    } else {
      // axios.post(API_URL+'/admin/auth/logoutall', {}, defaultOptions).then((response) => {
      //     console.log("user info response ", response.data);
      // });
      history.push(`/login`);
    }
    currentUser && localStorage.clear();
    localStorage.getItem("token") && localStorage.getItem("token");
  };
};

export const authMe = () => {
  const defaultOptions = {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  };
  let currentUser = JSON.parse(localStorage.getItem("collektoUser"));

  return (dispatch) => {
    dispatch({ type: adminConstants.LOGOUT });
    if (currentUser && currentUser.role == "COLLECTOR") {
      axios
        .get(API_URL + "/collector/auth/me", defaultOptions)
        .then((response) => {
          console.log("user auth info response ", response.data);
        })
        .catch((error) => {
          console.log(`auth me error`, error);
          localStorage.removeItem("collektoUser");
          localStorage.removeItem("token");
        });
      history.push(`/login`);
    } else if (currentUser && currentUser.role == "PARTNER") {
      history.push(`/login`);
    } else if (currentUser && currentUser.role == "CUSTOMER") {
      history.push(`/login`);
    } else if (currentUser && currentUser.role == "MANAGER") {
      localStorage.removeItem("collektoUser");
      localStorage.removeItem("token");
      history.push(`/login`);
    } else if (currentUser && currentUser.role == "FIELDAGENT") {
      history.push(`/login`);
    } else {
      axios
        .get(API_URL + "/admin/auth/me", defaultOptions)
        .then((response) => {
          console.log("user auth info response ", response.data);
        })
        .catch((error) => {
          console.log(`auth me error`, error);
          localStorage.removeItem("collektoUser");
          localStorage.removeItem("token");
          history.push(`/login`);
        });
    }
  };
};

export const updateMyProfile = (admin) => {
  console.log("udpate admin admin admin ", admin);
  return (dispatch) => {
    return axios.put(API_URL + "/admin", admin, defaultOptions).then((res) => {
      console.log("response ", res);

      history.push(`/`);
    });
  };
};

//     export const profileUpdate = (profile) => {
//         console.log("update rofile", profile);
//             return (dispatch) => {
//                 return axios.put(API_URL+'/profile/id', profile)
//                     .then((res) => {
//                         console.log("response ", res);

//                         history.push(`/profile/`)
//                     });
//             }
// }

export const deleteAdmin = (admin) => {
  return (dispatch) => {
    return axios.delete(API_URL + "/admin", { data: admin }).then((res) => {
      dispatch({ type: adminConstants.DELETE_ADMIN, payload: admin });
    });
  };
};
export const handleError = (error) => {
  console.log(`error `, error);
  // console.log(error.status)
  alertActions.error(error);
};
