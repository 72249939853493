import {
  clientConstants,
  successFeesConstants,
} from "../constants/ActionTypes";

const initialState = {
  clients: [],
  successfees: [],
  clientProfiling: {
    clientId: "",
    portfolioId: "",
    triggerUpdate: false,
    clientData: {},
    portfolioData: {},
  },
  uploadedPolicies: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case clientConstants.GET_UPLOADED_POLICIES:
      return { ...state, uploadedPolicies: [...payload] };
    case clientConstants.ADD_CLIENT_PROFILE:
      return {
        ...state,
        clientProfiling: { ...state.clientProfiling, ...payload },
      };
    case clientConstants.TRIGGER_UPDATE:
      return {
        ...state,
        clientProfiling: {
          ...state.clientProfiling,
          triggerUpdate: !state.clientProfiling?.triggerUpdate,
        },
      };

    case clientConstants.NEW_GET_CLIENT_ALL:
      return {
        ...state,
        clientProfiling: {
          ...state.clientProfiling,
          allClients: payload,
          triggerUpdate: !state.clients?.clientProfiling?.triggerUpdate,
        },
      };

    case clientConstants.ADD_SINGLE_CLIENT:
      return {
        ...state,
        clientProfiling: {
          ...state.clientProfiling,
          clientId: payload?.clientId ? payload.clientId : "",
          clientData: payload || {},
        },
      };

    case clientConstants.ADD_SINGLE_PORTFOLIO:
      return {
        ...state,
        clientProfiling: {
          ...state.clientProfiling,
          portfolioId: payload?.portfolioId ? payload?.portfolioId : "",
          portfolioData: payload || {},
        },
      };

    case clientConstants.GET_INCENTIVE_STRUCTURE:
      return {
        ...state,
        clientProfiling: {
          ...state.clientProfiling,
          incentiveData: payload || {},
        },
      };

    case clientConstants.GET_CLIENT_ALL:
      return { ...state, clients: payload };

    case successFeesConstants.GET_SUCCESS_FEES_ALL:
      return { ...state, successfees: payload };

    default:
      return state;
  }
};
