import { contactConstants } from "../constants/ActionTypes"

const initialState = {
    contact:[],
   
}

export default (state = initialState, { type, payload }) => {

    switch (type) {

    case contactConstants.GET_CONTACT_ALL:
        return { ...state, contact : payload }
  
    default:
        return state
    }

}

