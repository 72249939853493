import { userConstants, userDataConstants } from "../constants/ActionTypes"

const initialState = {
    users: [],
    userLists:[],
    deleteUserData:[]
}

const user = (state = initialState, { type, payload }) => {

    switch (type) {

    case userConstants.GET_USER_ALL:
        return { ...state, users : payload }
    case userDataConstants.GET_USERDATA_ALL:
        return {...state, userLists: payload }
 case userDataConstants.DELETE_USERDATA:
            return { ...state,
                userLists : state.userLists.filter(dv => (dv._id !== payload._id))
            }
    default:
        return state
    }

}

export default user

