import { backupConstants } from "../constants/ActionTypes"

const initialState = {
    backups:[],
}

export default (state = initialState, { type, payload }) => {
    switch (type) {

    case backupConstants.GET_BACKUP_ALL:
        return { ...state, backups:payload }
    default:
        return state
    }
}
