import Axios from "axios"
import { API_URL, userDataConstants } from "../constants/ActionTypes"
import { alertActions } from "./alertAction"
import history from "../history"
const defaultOptions = { headers: { 'Authorization': 'Bearer '+localStorage.getItem('token'), } };


export const getUserDataAll = () => dispatch => {
    console.log(`getUserDataAll`)
    return Axios.get(`${API_URL}/userData/all`,defaultOptions).then(res => {
        console.log(`getUserDataAll data `, res.data)
        dispatch({type : userDataConstants.GET_USERDATA_ALL, payload : res.data})
    }).catch(handleError)
}
export const addUserData = (data) => dispatch => {
    // console.log(`getManagerAll`)
    console.log(`add user data `, data)
    return Axios.post(`${API_URL}/userData`, data,defaultOptions).then(res => {
        console.log(`res user data `, res.data)
        // dispatch({type : managerConstants.ADD_MANAGER, payload : res.data})
        history.push(`/userdata`)
    }).catch(handleError)
}

export const deleteUserData = (data) => {
    return (dispatch) => {
        return Axios.delete(`${API_URL}/userData`, {data : data})
            .then((res) => {
                dispatch({ type: userDataConstants.DELETE_USERDATA, payload : data })
                // history.push(`/managers-collectors`)
            });
    }
}


export const updateUserData = (data) => dispatch => {
    // console.log(`getManagerAll`)
    console.log(`update user data `, data)
    return Axios.put(`${API_URL}/userData`, data,defaultOptions).then(res => {
        console.log(`update res user data `, res.data)
        // dispatch({type : managerConstants.ADD_MANAGER, payload : res.data})
        history.push(`/userData`)
    }).catch(handleError)
}


export const handleError = (error) => {
    console.log(`error `, error)
    alertActions.error(error);
}