import React from 'react';
import { connect } from 'react-redux';
import Spinner from "./Spinner";// Adjust the path based on your project structure

const SpinnerContainer = ({ isLoading }) => {
    return isLoading ? <Spinner /> : null;
};

const mapStateToProps = state => {
    return ({
        isLoading: state?.authentication?.loading // Adjust according to your state structure
    })
};

export default connect(mapStateToProps)(SpinnerContainer);
