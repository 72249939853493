import { partnerloanConstants } from "../constants/ActionTypes";
const initialState = {
    partnerloans: [],
    pageNum:0,
    loading: false,
}
export default (state = initialState, {type, payload}) => {
    switch (type) {
        case partnerloanConstants.GET_PARTNER_ALL_LOANS:
        return  {
            ...state,
            partnerloans: payload
        }

        case partnerloanConstants.GET_PARTNER_EXTENSION_LOANS:
        return {
            ...state,
            partnerloans: payload
        }

        default:
            return state;
    }
};