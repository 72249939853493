export const Role = {
  ADMIN: "ADMIN",
  PARTNER: "PARTNER",
  COLLECTOR: "COLLECTOR",
  SUPER_ADMIN: "SUPER_ADMIN",
  CUSTOMER: "CUSTOMER",
  MANAGER: "MANAGER",
  CLIENT_ADMIN: "CLIENT_ADMIN",
  CLIENT: "CLIENT"
};
