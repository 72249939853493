import { analyticalConstants } from "../constants/ActionTypes"

const initialState = {
    overall: [],
    overallTest: [],
    overallByManager: [],
    encoreReport: {},
    lastMonthData: [],
    bestMonthObj: [],
    groupByBestMonth: [],
    bestMonthManagerObj: [],
    lastInteractionReports: [],
    retainedCases: [],
    allocationTable: {}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
    switch (type) {

        case analyticalConstants.GET_OVERALL_ANALYTICS:
            // console.log(`reducer `, [...state.overall, ...payload])
            let overall = [...state.overall, ...payload]
            return { ...state, overall: overall }

        case analyticalConstants.GET_RETAINED_REPORT:
            let retained;
            if (typeof payload === "object") {
                retained = [...payload];
            }
            else {
                retained = [];
            }
            return { ...state, retainedCases: retained };

        case analyticalConstants.GET_LOGIN_REPORT:
            return { ...state, loginReport: payload }

        case analyticalConstants.GET_OVERALL_TEST_ANALYTICS:
            console.log(`payload test_analytics `, payload)
            return { ...state, overallTest: payload }

        case analyticalConstants.GET_ALLOCATION_TABLE:
            // console.log(`payload test_analytics `, payload)
            return { ...state, allocationTable: payload }

        case analyticalConstants.DESELECT_COLLECTOR_ANALYTICS:
            return { ...state, overall: state.overall.filter(s => s.collectorId !== payload._id) }


        case analyticalConstants.CLEAR_COLLECTOR_ANALYTICS:
            return { ...state, overall: payload }

        case analyticalConstants.GET_OVERALL_BY_MANAGER:
            return { ...state, overallByManager: payload }

        case analyticalConstants.LAST_MONTH_AMOUNT:
            console.log(`reducer analytical `, payload)
            return { ...state, lastMonthData: payload }

        case analyticalConstants.LAST_INTERACTION_REPORTS:
            console.log(`reducer analytical `, payload)
            return { ...state, lastInteractionReports: payload }


        case analyticalConstants.BEST_MONTH_AMOUNT:
            console.log(`reducer bestMonthObj `, payload)
            return { ...state, bestMonthObj: payload }

        case analyticalConstants.ENCORE_REPORT:
            console.log(`reducer bestMonthObj `, payload)
            return { ...state, encoreReport: payload }


        case analyticalConstants.BEST_MONTH_MANAGER:
            console.log(`reducer bestMonthObj `, payload)
            return { ...state, bestMonthManagerObj: payload }


        case analyticalConstants.BEST_GROUPBY_MONTH_AMOUNT:
            console.log(`reducer groupby bestMonthObj `, payload)
            return { ...state, groupByBestMonth: payload }

        case analyticalConstants.GET_WEIGHTED_DATA:
            return { ...state, mtdData: payload }

        default:
            return state
    }
}