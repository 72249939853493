import Axios from "axios";
import { API_URL, JAVA_API_URL, collectorConstants } from "../constants/ActionTypes";
import { alertActions } from "./alertAction";
import history from "../history";
import "dotenv";
import apiAxios from "../api";
const { REACT_APP_JAVA_API_URL } = process.env;
const defaultOptions = {
  headers: { Authorization: "Bearer " + localStorage.getItem("token") },
};
const collektoUser = JSON.parse(localStorage.getItem("collektoUser"));

export const getCollectorAll = (createdBy) => (dispatch) => {
  console.log(`getCollectorAll`);
  return Axios.get(
    `${API_URL}/collector/all?createdBy=${createdBy}`,
    defaultOptions
  )
    .then((res) => {
      dispatch({
        type: collectorConstants.GET_COLLECTOR_ALL,
        payload: res.data,
      });
    })
    .catch(handleError);
};

export const getCollectorProcess = (username) => (dispatch) => {
  console.log(`getCollectorAll`);
  const options = { headers: { ...defaultOptions.headers, username } };
  return Axios.get(
    `${JAVA_API_URL}/api/v2/dialer/process`,
    options
  )
    .then((res) => {
      dispatch({
        type: collectorConstants.GET_COLLECTOR_PROCESS,
        payload: (res.data?.data || {}),
      });
    })
    .catch(handleError);
};

export const getMissedAlerts = (collectorId) => (dispatch) => {
  console.log("defaultOptions actually", defaultOptions);
  let options = { ...defaultOptions };
  options.headers.collectorId = collectorId;
  options.mode = "no-cors";
  return Axios.get(`${REACT_APP_JAVA_API_URL}/api/v2/alerts/missed`, options)
    .then((res) => {
      dispatch({
        type: collectorConstants.GET_ALERTS_COUNT,
        payload: res.data?.data.length,
      });
      dispatch({
        type: collectorConstants.GET_MISSED_ALERTS,
        payload: res.data,
      });
    })
    .catch(handleError);
};

export const getCollectorNotificationsCount = () => (dispatch) => {
  let options = {
    headers: { ...defaultOptions.headers, collectorId: collektoUser?._id },
  };
  Axios.get(`${REACT_APP_JAVA_API_URL}/api/v2/notifications/count`, options)
    .then(({ data }) => {
      const count = data.data?.notificationCount;
      dispatch({
        type: collectorConstants.GET_NOTIFICATION_COUNT,
        payload: { count },
      });
    })
    .catch(handleError);
};

export const handleModal = (payload) => (dispatch) => {
  dispatch({ type: collectorConstants.SET_MODAL, payload });
};

export const getAllAlerts = (collectorId) => (dispatch) => {
  let options = { ...defaultOptions };
  options.headers.collectorId = collectorId;
  options.mode = "no-cors";
  return Axios.get(`${REACT_APP_JAVA_API_URL}/api/v2/alerts`, options).then(
    (res) => {
      dispatch({
        type: collectorConstants.GET_ALERTS_ALL,
        payload: res.data?.data,
      });
    }
  );
};

export const getAttendance = (collectorId) => (dispatch) => {
  console.log(`getCollectorAll`);
  return Axios.get(
    `${API_URL}/collector/attendance?collectorId=${collectorId}`,
    defaultOptions
  )
    .then((res) => {
      dispatch({ type: collectorConstants.GET_ATTENDANCE, payload: res.data });
    })
    .catch(handleError);
};

export const getCollectorByManager = (managerId) => (dispatch) => {
  console.log(`getCollectorAll`);
  let query = managerId ? `?managerId=${managerId}` : "";
  return apiAxios
    .get(`${API_URL}/collector/by/manager${query}`, defaultOptions)
    .then((res) => {
      dispatch({
        type: collectorConstants.GET_COLLECTOR_ALL,
        payload: res.data,
      });
    })
    .catch(handleError);
};

export const getActiveCollectorByManager = (managerId, client) => async (dispatch) => {
  let query = managerId ? `?managerId=${managerId}&Client_Name=${client}` : "";
  return await apiAxios
    .get(`${API_URL}/collector/active/by/manager${query}`, defaultOptions)
    .then((res) => {
      dispatch({
        type: collectorConstants.GET_COLLECTOR_ALL,
        payload: res.data,
      });
    })
    .catch(handleError);
}

export const getAllocatedCount = (createdBy) => (dispatch) => {
  console.log(`getCollectorAll`, createdBy);
  return Axios.get(
    `${API_URL}/activity/collector/allocations?createdBy=${createdBy}`,
    defaultOptions
  )
    .then((res) => {
      console.log(`res.data `, res.data);
      dispatch({
        type: collectorConstants.COLLECTOR_ALLOCATED_COUNT,
        payload: res.data,
      });
    })
    .catch(handleError);
};

export const addCollector = (data) => (dispatch) => {
  return Axios.post(`${API_URL}/collector`, data, defaultOptions)
    .then((res) => {
      history.push(`/managers-collectors`);
    })
    .catch(handleError);
};

export const updateCollector = (data) => (dispatch) => {
  return Axios.put(`${API_URL}/collector`, data, defaultOptions)
    .then((res) => {
      history.push(`/managers-collectors`);
    })
    .catch(handleError);
};

export const deleteCollector = (data, newData) => {
  // if(newData){
  //     data = newData;//added this line to not disturb the existing function as it was initially accepting only one parameter i.e data
  // }
  return (dispatch) => {
    return Axios.delete(
      `${API_URL}/collector/${data?._id}`,
      defaultOptions
    ).then((res) => {
      dispatch({ type: collectorConstants.DELETE_COLLECTOR, payload: data });
      // history.push(`/managers-collectors`)
    });
  };
};

export const handleClickInfo = (message, data, deactivate) => {
  alertActions.clickableInfo(message, data, deactivate);
};

export const handleError = (error) => {
  console.log(`error `, error);
  alertActions.error(error);
};
