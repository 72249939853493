import React, { Component, Suspense } from "react";
import { Provider } from "react-redux";
import store from "./store/configureStore";
import { Router } from "react-router-dom";
import history from "./history";
import Routes from "./Routes";
import "../node_modules/react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
const { REACT_APP_BASE_NAME } = process.env;

// const NewHOC = (PassedComponent) => {
//     return class extends React.Component {
//       render() {
//         return (
//           <div>
//             <PassedComponent {...this.props} />
//           </div>
//         )
//       }
//     }
//   }

//   const Movie = ({name}) => <div>{name}</div>
// const NewComponent = NewHOC(Movie);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTimeout: false,
    };
    console.log(`props app`, props.authentication);
  }

  render() {
    return (
      <div className="App">
        <Suspense fallback={<div>Loading...</div>}>
          <NotificationContainer />

          <Provider store={store}>
            <Router basename={REACT_APP_BASE_NAME} history={history}>
              <Routes />
            </Router>
          </Provider>
        </Suspense>
      </div>
    );
  }
}

export default App;
