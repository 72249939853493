import React from 'react';
import "./spinner.scss";
import SpinnerModule from './spinner.module.scss';

const Spinner = () => (
    <>
        <div className='parent-container position-absolute zIndexHigh' style={{ width: '100%', backgroundColor: "#c2c2c2", opacity: "0.2", zIndex: "2000", filter: 'blur("20px")' }}></div>
        <div className={`d-flex justify-content-center large-spinner ${SpinnerModule.container}`}>
            <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    </>
);

export default Spinner;
