import { employeeConstants } from "../constants/ActionTypes"

const initialState = {
    employees: []
}

export default (state = initialState, { type, payload }) => {
    switch (type) {

    case employeeConstants.GET_EMPLOYEE_ALL:
        return { ...state, employees : payload }

    default:
        return state
    }
}
